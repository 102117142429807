import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { Button, Input } from "@nextui-org/react";
import { useCallback, useState } from "react";
import { client as opaqueClient } from "@serenity-kit/opaque";
import {
  BeginRegistrationResponse,
  ContinueUpdatePasswordRequest,
  FinishUpdatePasswordRequest,
  StartLoginResponse,
  StartUpdatePasswordRequest,
} from "../gql/graphql";
import {
  decryptSymmetric,
  deriveKeyFromMnemonic,
  hashString,
  generateMnemonic,
  encryptSymmetric,
  fromByteArray,
  toByteArray,
  fromByteArrayB64,
  setExportKey,
} from "../crypto/utils";
import { useNavigate } from "react-router-dom";

const START_LOGIN_QUERY = gql`
  query StartUpdatePassword($startLoginRequest: String!) {
    startUpdatePassword(request: { startLoginRequest: $startLoginRequest }) {
      loginResponse
      userID
    }
  }
`;
const GET_ENCRYPTED_DECRYPTION_KEY = gql`
  query getEncryptedDecryptionKey {
    getEncryptedDecryptionKey
  }
`;

const UPDATE_PASSWORD_QUERY = gql`
  mutation UpdatePassword(
    $previousHashedExportKey: String!
    $encryptedPrivateKey: String!
    $encryptedExportKey: String!
    $hashedExportKey: String!
    $decryptedExportKey: String
    $registrationRecord: String!
  ) {
    finishUpdatePassword(
      request: {
        previousHashedExportKey: $previousHashedExportKey
        encryptedPrivateKey: $encryptedPrivateKey
        encryptedExportKey: $encryptedExportKey
        hashedExportKey: $hashedExportKey
        decryptedExportKey: $decryptedExportKey
        registrationRecord: $registrationRecord
      }
    ) {
      success
    }
  }
`;

const CONTINUE_UPDATE_PASSWORD_QUERY = gql`
  query ContinueUpdatePassword($registrationRequest: String!) {
    continueUpdatePassword(
      request: { registrationRequest: $registrationRequest }
    ) {
      registrationResponse
    }
  }
`;

const LOGOUT_QUERY = gql`
  query Logout {
    logout
  }
`;

function UpdatePassword() {
  const navigate = useNavigate();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [backupExportKey, setBackupExportKey] = useState(false);
  const [startLoginQuery] = useLazyQuery(START_LOGIN_QUERY, {
    fetchPolicy: "no-cache",
  });
  const [updatePasswordMutation] = useMutation(UPDATE_PASSWORD_QUERY);
  const [continueUpdatePasswordQuery] = useLazyQuery(
    CONTINUE_UPDATE_PASSWORD_QUERY,
    { fetchPolicy: "no-cache" }
  );
  const [getEncryptedDecryptionKey] = useLazyQuery(
    GET_ENCRYPTED_DECRYPTION_KEY,
    { fetchPolicy: "no-cache" }
  );
  const [logoutQuery] = useLazyQuery(LOGOUT_QUERY, { fetchPolicy: "no-cache" });
  const updatePassword = useCallback(async () => {
    if (newPassword !== confirmNewPassword) {
      alert("Passwords do not match");
      return;
    }
    const { clientLoginState, startLoginRequest } = opaqueClient.startLogin({
      password: oldPassword,
    });
    const loginResult: {
      data: { startUpdatePassword: StartLoginResponse };
    } = await startLoginQuery({
      variables: {
        startLoginRequest,
      },
    });
    const opaqueLoginResult = opaqueClient.finishLogin({
      clientLoginState,
      loginResponse: loginResult.data.startUpdatePassword.loginResponse,
      password: oldPassword,
    });
    if (!opaqueLoginResult) {
      alert("Invalid password");
      return;
    }
    // if we're past here, we have a successful login
    const previousHashedExportKey = hashString(opaqueLoginResult.exportKey);

    const result: { data: { getEncryptedDecryptionKey: string } } =
      await getEncryptedDecryptionKey();
    const decryptionKey = decryptSymmetric({
      secretKey: opaqueLoginResult.exportKey,
      encryptedPayload: result.data.getEncryptedDecryptionKey,
    });
    const { clientRegistrationState, registrationRequest } =
      opaqueClient.startRegistration({ password: newPassword });

    const beginRegistrationVariables: ContinueUpdatePasswordRequest = {
      registrationRequest,
    };
    const registrationResponse: {
      data: { continueUpdatePassword: BeginRegistrationResponse };
    } = await continueUpdatePasswordQuery({
      variables: beginRegistrationVariables,
    });
    const opaqueFinishRegistration = opaqueClient.finishRegistration({
      clientRegistrationState,
      registrationResponse:
        registrationResponse.data.continueUpdatePassword.registrationResponse,
      password: newPassword,
    });
    const newExportKey = opaqueFinishRegistration.exportKey;
    const hashedExportKey = hashString(newExportKey);
    const mnemonic = generateMnemonic();
    const mnemonicKey = deriveKeyFromMnemonic({ mnemonic });
    const encryptedExportKey = encryptSymmetric({
      secretKey: fromByteArrayB64(mnemonicKey),
      decryptedPayload: toByteArray(newExportKey),
    });
    const encryptedDecryptionKey = encryptSymmetric({
      secretKey: newExportKey,
      decryptedPayload: decryptionKey,
    });
    const updatePasswordVariables: FinishUpdatePasswordRequest = {
      previousHashedExportKey,
      encryptedPrivateKey: encryptedDecryptionKey,
      encryptedExportKey,
      hashedExportKey,
      decryptedExportKey: backupExportKey ? newExportKey : null,
      registrationRecord: opaqueFinishRegistration.registrationRecord,
    };
    await updatePasswordMutation({
      variables: updatePasswordVariables,
    });
    setExportKey(newExportKey);
  }, [
    backupExportKey,
    confirmNewPassword,
    continueUpdatePasswordQuery,
    getEncryptedDecryptionKey,
    newPassword,
    oldPassword,
    startLoginQuery,
    updatePasswordMutation,
  ]);

  const logout = useCallback(async () => {
    await logoutQuery();
    setExportKey("");
    navigate("/login");
  }, [logoutQuery, navigate]);
  return (
    <div>
      <h1>Update Password</h1>
      <Input
        placeholder="Old Password"
        value={oldPassword}
        onValueChange={setOldPassword}
      />
      <Input
        placeholder="New Password"
        value={newPassword}
        onValueChange={setNewPassword}
      />
      <Input
        placeholder="Confirm New Password"
        value={confirmNewPassword}
        onValueChange={setConfirmNewPassword}
      />
      <Button onClick={updatePassword}>Update Password</Button>
      <Button onClick={logout}>Logout</Button>
    </div>
  );
}

export default UpdatePassword;
