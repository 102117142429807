import PlaintextTitleHeader from "../components/PlaintextTitleHeader";
import { Button, Input, Textarea, Modal, ModalContent, ModalHeader, ModalBody } from "@nextui-org/react";
import { useState, useRef, useMemo, useEffect } from "react";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { ImageUploadLinkResponse } from "../gql/graphql";

const CREATE_WRITING_CIRCLE = gql`
  mutation CreateWritingCircle(
    $name: String!
    $description: String
    $profileImageParameters: ImageParameters
    $presetID: String
  ) {
    createWritingCircle(
      request: {
        name: $name
        description: $description
        profileImageParameters: $profileImageParameters
        presetID: $presetID
      }
    ) {
      resourceID
    }
  }
`;

const GET_IMAGE_UPLOAD_URL = gql`
  query GetImageUploadURL {
    getImageUploadLink {
      link
      imageID
      fields
    }
  }
`;

const GET_ROOM_IMAGE_PRESETS = gql`
  query GetRoomImagePresets {
    getRoomImagePresets {
      presetID
      imageURL
    }
  }
`;

export default function CreateWritingCirclePage() {
  const navigate = useNavigate();
  const [title, setTitle] = useState("New Writing Circle");
  const [description, setDescription] = useState("");
  const [imageSelectorOpen, setImageSelectorOpen] = useState(false);
  const [createWritingCircle] = useMutation(CREATE_WRITING_CIRCLE);
  const [getImageUploadURL] = useLazyQuery(GET_IMAGE_UPLOAD_URL, {
    fetchPolicy: "no-cache",
  });
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [selectedPresetID, setSelectedPresetID] = useState("");

  const { data: presetData, loading } = useQuery(GET_ROOM_IMAGE_PRESETS);

  useEffect(() => {
    if (presetData?.getRoomImagePresets.length > 0 && selectedPresetID === "") {
      setSelectedPresetID(presetData.getRoomImagePresets[0].presetID);
    }
  }, [presetData, selectedPresetID]);

  const objectURL = useMemo(
    () => (selectedFile ? URL.createObjectURL(selectedFile) : ""),
    [selectedFile]
  );

  const selectedImageURL = useMemo(() => {
    return selectedPresetID === "custom"
      ? objectURL
      : presetData?.getRoomImagePresets.find(
          (preset: { presetID: string; imageURL: string }) =>
            preset.presetID === selectedPresetID
        )?.imageURL || "";
  }, [selectedPresetID, objectURL, presetData]);

  return (
    <div className="flex flex-col w-full h-full">
      <PlaintextTitleHeader title="Create writing circle" />
      <Modal
        isOpen={imageSelectorOpen}
        onClose={() => setImageSelectorOpen(false)}
        size="2xl"
        style={{
          maxHeight: "60vh",
          overflowY: "auto",
        }}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader
                className="font-sans text-lg font-bold"
                style={{
                  position: "sticky",
                  top: 0,
                  backgroundColor: "white",
                  zIndex: 1,
                  padding: "16px",
                  borderBottom: "1px solid #e5e5e5",
                }}
              >
                Select profile image
              </ModalHeader>
              <ModalBody>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(3, 1fr)",
                    gap: "16px",
                    padding: "16px",
                  }}
                >
                  {presetData?.getRoomImagePresets.map(
                    (preset: { presetID: string; imageURL: string }) => (
                      <div
                        key={preset.presetID}
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setSelectedPresetID(preset.presetID);
                          setImageSelectorOpen(false);
                        }}
                      >
                        <div
                          style={{
                            backgroundImage: `url(${preset.imageURL})`,
                            backgroundSize: "cover",
                            width: "100%",
                            paddingBottom: "100%",
                            borderRadius: "8px",
                            transition: "opacity 0.2s",
                          }}
                          onMouseEnter={(e) =>
                            (e.currentTarget.style.opacity = "0.8")
                          }
                          onMouseLeave={(e) =>
                            (e.currentTarget.style.opacity = "1")
                          }
                        />
                      </div>
                    )
                  )}
                  <div
                    style={{
                      width: "100%",
                      position: "relative",
                      paddingTop: "100%",
                      borderRadius: "8px",
                      border: "2px dashed #ccc",
                      overflow: "hidden",
                    }}
                  >
                    <div
                      className="font-sans"
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 2,
                        backgroundImage: `url(${objectURL})`,
                        backgroundSize: "cover",
                        cursor: objectURL ? "pointer" : "default",
                      }}
                      onClick={() => {
                        if (objectURL) {
                          setSelectedPresetID("custom");
                          setImageSelectorOpen(false);
                        }
                      }}
                    >
                      <div
                        className="flex flex-col items-center"
                        style={{
                          backgroundColor: "rgba(255, 255, 255, 0.9)",
                          boxShadow:
                            "0px 0px 25px 25px rgba(255, 255, 255, 0.9)",
                        }}
                      >
                        <Button
                          size="sm"
                          variant="bordered"
                          onClick={() => {
                            fileInputRef.current?.click();
                          }}
                        >
                          Choose File
                        </Button>
                        <span
                          style={{
                            marginTop: "8px",
                            fontSize: "12px",
                          }}
                        >
                          {selectedFile ? selectedFile.name : "No file chosen"}
                        </span>
                      </div>
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: "#f0f0f0",
                        zIndex: 1,
                      }}
                    />
                    <input
                      ref={fileInputRef}
                      type="file"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        const file = e.target.files?.[0];
                        if (file) {
                          setSelectedFile(file);
                          setSelectedPresetID("custom");
                          setImageSelectorOpen(false);
                        }
                      }}
                    />
                  </div>
                </div>
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>
      <div className="flex flex-col flex-1 items-center">
        <div
          className="font-sans"
          style={{
            marginTop: 20,
            width: 400,
            maxWidth: "100%",
          }}
        >
          <div style={{ marginBottom: 20, fontSize: 20, fontWeight: "bold" }}>
            <p>Writing Circle Settings</p>
          </div>
          <div
            className="setting-row flex flex-row"
            style={{ marginBottom: 20 }}
          >
            <div
              style={{
                backgroundImage: `url(${selectedImageURL})`,
                backgroundSize: "cover",
                minWidth: 60,
                minHeight: 60,
                borderRadius: 10,
                marginBottom: 10,
                marginRight: 10,
              }}
              onClick={() => setImageSelectorOpen(true)}
              className="cursor-pointer"
            />
            <div
              className="flex flex-col"
              style={{ marginLeft: 10, width: "100%" }}
            >
              <div style={{ marginBottom: 6 }}>
                <p style={{ fontSize: 14 }}>Title</p>
              </div>

              <Input
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                size="sm"
                style={{ fontSize: 14 }}
                classNames={{
                  inputWrapper: ["h-[24px]"],
                }}
                className="w-full"
              />
            </div>
          </div>

          <hr
            className="border-t border-gray-200"
            style={{ margin: "20px 0" }}
          />

          <div className="setting-row" style={{ marginBottom: 20 }}>
            <div className="flex items-center w-full">
              <Textarea
                value={description}
                style={{ fontSize: 14 }}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Enter description"
                className="flex-1"
                minRows={3}
                maxRows={5}
              />
            </div>
          </div>

          <div className="flex justify-end" style={{ marginTop: 40 }}>
            <Button
              data-testid="button-create-writing-circle"
              color="primary"
              size="sm"
              style={{ height: 34, fontSize: 14 }}
              onClick={async () => {
                if (selectedPresetID === "custom" && selectedFile) {
                  const reader = new FileReader();
                  reader.readAsArrayBuffer(selectedFile);
                  reader.onload = async (e) => {
                    const form = new FormData();
                    const binaryStr = reader.result as ArrayBuffer;
                    const response: {
                      data: { getImageUploadLink: ImageUploadLinkResponse };
                    } = await getImageUploadURL();
                    Object.entries(
                      response.data.getImageUploadLink.fields
                    ).forEach(([field, value]) => {
                      form.append(field, value as string);
                    });
                    form.append("file", new Blob([new Uint8Array(binaryStr)]));

                    const fetchResult = await fetch(
                      response.data.getImageUploadLink.link,
                      {
                        method: "POST",
                        body: form,
                      }
                    );
                    if (fetchResult.ok) {
                      const { data } = await createWritingCircle({
                        variables: {
                          name: title,
                          description,
                          profileImageParameters: {
                            imageID: response.data.getImageUploadLink.imageID,
                            bucket:
                              response.data.getImageUploadLink.fields.bucket,
                            key: response.data.getImageUploadLink.fields.key,
                          },
                        },
                      });
                      if (data) {
                        navigate(`/circle/${data.createWritingCircle.resourceID}`);
                      }
                    }
                  };
                } else {
                  const { data } = await createWritingCircle({
                    variables: {
                      name: title,
                      description,
                      presetID: selectedPresetID,
                    },
                  });
                  if (data) {
                    navigate(`/circle/${data.createWritingCircle.resourceID}`);
                  }
                }
              }}
            >
              Create writing circle
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
