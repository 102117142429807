import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  Spinner,
} from "@nextui-org/react";
import CharacterCount from "@tiptap/extension-character-count";
import StarterKit from "@tiptap/starter-kit";
import { LiteralTab } from "../utils/tabExtension";
import Placeholder from "@tiptap/extension-placeholder";
import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useUserInfo } from "../hooks/useUserInfo";
import { DraftMetadata as gqlDraftMetadata } from "../gql/graphql";
import { decryptSymmetricString } from "../crypto/utils";
import { EditorProvider } from "@tiptap/react";
import { Add, ArrowRight } from "iconsax-react";
import TextStyle from "@tiptap/extension-text-style";
import ListItem from "@tiptap/extension-list-item";
import { getDraftPath } from "../utils/urlPaths";
import { useMediaQuery } from "react-responsive";
import { useInterval } from "../hooks/useInterval";

const GET_ALL_DRAFTS = gql`
  query GetAllDrafts {
    getAllDrafts {
      drafts {
        draftID
        encryptedTitle
        updatedAt
      }
    }
  }
`;

const GET_DRAFT = gql`
  query GetDraft($draftID: String!) {
    getDraft(request: { draftID: $draftID }) {
      draftID
      encryptedDraftHtml
    }
  }
`;

const CREATE_DRAFT = gql`
  mutation CreateDraft {
    createDraft
  }
`;

const extensions = [
  // @ts-ignore
  TextStyle.configure({ type: [ListItem.name] }),
  StarterKit.configure({
    bulletList: {
      keepMarks: true,
      keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
    },
    codeBlock: false,
    code: false,
  }),
  CharacterCount.configure(),
  LiteralTab.configure(),
  Placeholder.configure({
    placeholder: "Draft",
  }),
];

type DraftMetadata = {
  draftID: string;
  title: string | null;
  updatedAt: Date;
};

export default function DraftSelectModal({
  open,
  setOpen,
  roomID,
  dismissable,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  roomID?: string;
  dismissable: boolean;
}) {
  const { draftID } = useParams();
  const isSmallWidth = useMediaQuery({
    query: "(max-width: 640px)",
  });
  const [createDraft] = useMutation(CREATE_DRAFT);
  const { data } = useQuery(GET_ALL_DRAFTS, {
    fetchPolicy: "no-cache",
  });

  const [getDraft, { data: draftData, loading: draftLoading }] = useLazyQuery(
    GET_DRAFT,
    {
      fetchPolicy: "no-cache",
    }
  );

  const [hovered, setHovered] = useState<string | null>(null);

  const [selectedDraftID, setSelectedDraftID] = useState<string | null>(null);

  const navigate = useNavigate();

  const { decryptionKey } = useUserInfo();

  const draftMetadataList = useMemo<DraftMetadata[]>(() => {
    if (!data || !decryptionKey) {
      return [];
    }
    return data.getAllDrafts.drafts.map((metadata: gqlDraftMetadata) => {
      return {
        draftID: metadata.draftID,
        title: decryptSymmetricString({
          encryptedPayload: metadata.encryptedTitle,
          secretKey: decryptionKey,
        }),
        updatedAt: new Date(metadata.updatedAt),
      };
    });
  }, [data, decryptionKey]);

  const decryptedDraftData = useMemo(() => {
    if (!draftData || !decryptionKey) {
      return;
    }
    const decryptedDraftHtml = draftData.getDraft?.encryptedDraftHtml
      ? decryptSymmetricString({
          secretKey: decryptionKey,
          encryptedPayload: draftData.getDraft.encryptedDraftHtml,
        })
      : null;
    return {
      draftHtml: decryptedDraftHtml,
    };
  }, [decryptionKey, draftData]);
  useEffect(() => {
    if (selectedDraftID) {
      getDraft({ variables: { draftID: selectedDraftID } });
    }
  }, [getDraft, selectedDraftID]);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const rightColumnWidth = Math.min(748, windowWidth - 50);

  const modalDiv = useRef<HTMLDivElement>(null);

  const [interval, setInterval] = useState<number | null>(null);
  useEffect(() => {
    setInterval(10);
  }, [isSmallWidth]);

  useInterval(() => {
    if (modalDiv.current && modalDiv.current.parentElement) {
      modalDiv.current.parentElement.style.alignItems = "center";
      modalDiv.current.parentElement.style.transform = "none";
      modalDiv.current.parentElement.style.marginTop = "29px";
      modalDiv.current.parentElement.style.overflowY = "hidden";
    }
  }, interval);

  return (
    <Modal
      isOpen={open}
      onClose={() => setOpen(false)}
      isDismissable={dismissable}
      hideCloseButton={!dismissable}
      className="draft-select-modal"
      ref={modalDiv}
    >
      <ModalContent style={{ minWidth: rightColumnWidth }}>
        <ModalHeader className="font-sans">Select draft</ModalHeader>
        <ModalBody>
          <div className="flex flex-row h-full">
            <div
              style={{
                overflowY: "auto",
                borderRightWidth: 1,
                borderColor: "black",
                height: Math.min(500, windowHeight - 200),
                minWidth: 200,
              }}
            >
              <div
                className="font-sans cursor-pointer"
                style={{
                  paddingTop: 10,
                  paddingBottom: 10,
                  backgroundColor:
                    hovered === "create-new" ? "#f0f0f0" : "white",
                }}
                onMouseEnter={() => {
                  setHovered("create-new");
                }}
                onMouseLeave={() => {
                  setHovered(null);
                }}
                onClick={async () => {
                  const response: { data?: { createDraft: string } } =
                    await createDraft();
                  if (!response.data) {
                    console.error("Failed to create draft");
                    return;
                  }
                  navigate(
                    getDraftPath({
                      draftID: response.data.createDraft,
                      roomID,
                    })
                  );
                }}
              >
                <div
                  className="flex flex-row items-center"
                  style={{ color: "#456577" }}
                >
                  <div style={{ marginRight: 10 }}>
                    <Add />
                  </div>
                  <div>
                    <p
                      style={{
                        fontSize: 16,

                        fontWeight: "bold",
                      }}
                    >
                      Create new
                    </p>

                    <p style={{ fontSize: 10 }}>Today</p>
                  </div>
                </div>
              </div>
              {draftMetadataList &&
                draftMetadataList.map((draft) => {
                  return (
                    <div
                      key={draft.draftID}
                      style={{
                        padding: 10,
                        backgroundColor:
                          hovered === draft.draftID
                            ? "#f0f0f0"
                            : selectedDraftID === draft.draftID
                            ? "#7194A8"
                            : "white",
                        cursor: "pointer",
                      }}
                      onMouseEnter={() => {
                        setHovered(draft.draftID);
                      }}
                      onMouseLeave={() => {
                        setHovered(null);
                      }}
                      onClick={() => {
                        setSelectedDraftID(draft.draftID);
                      }}
                    >
                      <p
                        className="font-sans"
                        style={{ fontSize: 16, fontWeight: "bold" }}
                      >
                        {draft.title ?? "Untitled"}
                      </p>
                      <p className="font-sans" style={{ fontSize: 10 }}>
                        {draft.updatedAt.toLocaleDateString("en-US", {
                          month: "long",
                          day: "numeric",
                          year: "numeric",
                        })}
                      </p>
                    </div>
                  );
                })}
            </div>
            <div style={{ width: "100%" }}>
              {draftLoading && <Spinner />}
              {!selectedDraftID && !draftLoading && (
                <div style={{ padding: 20 }}>
                  <p className="font-sans">Select a draft to view</p>
                </div>
              )}

              {!draftLoading && selectedDraftID && (
                <div
                  style={{
                    padding: 20,
                    maxHeight: 500,
                    display: "flex",
                    flexDirection: "column",
                    overflowY: "auto",
                  }}
                >
                  <div style={{ flex: 1 }}>
                    {selectedDraftID &&
                      !decryptedDraftData?.draftHtml &&
                      !draftLoading && (
                        <div>
                          <p className="font-sans" style={{ color: "#d4d4d8" }}>
                            Empty
                          </p>
                        </div>
                      )}
                    {decryptedDraftData?.draftHtml && (
                      <EditorProvider
                        slotBefore={null}
                        slotAfter={null}
                        extensions={extensions}
                        content={decryptedDraftData?.draftHtml}
                        editorProps={{
                          attributes: {
                            className: "h-full font-serif",
                          },
                          transformPastedHTML: (html: string) => {
                            // any desired html transformations can go here
                            return html;
                          },
                        }}
                        editable={false}
                      >
                        {null}
                      </EditorProvider>
                    )}
                  </div>

                  <div
                    className="flex flex-row justify-end"
                    style={{ position: "absolute", bottom: 20, right: 30 }}
                  >
                    <Button
                      className="font-sans"
                      color="primary"
                      endContent={<ArrowRight />}
                      size="sm"
                      onClick={() => {
                        // if already on that draft, close modal
                        if (draftID === selectedDraftID) {
                          setOpen(false);
                          return;
                        }
                        navigate(
                          getDraftPath({ draftID: selectedDraftID, roomID })
                        );
                      }}
                    >
                      ENTER{roomID ? " ROOM" : ""}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
