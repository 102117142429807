import { gql, useLazyQuery } from "@apollo/client";
import { Button, Input } from "@nextui-org/react";
import { useCallback, useState } from "react";
import {
  FinishLoginRequest,
  StartLoginRequest,
  StartLoginResponse,
} from "../gql/graphql";
import { client as opaqueClient } from "@serenity-kit/opaque";
import { Link, useNavigate } from "react-router-dom";
import { clearLocalStorage, setExportKey } from "../crypto/utils";
import { Eye, EyeSlash } from "iconsax-react";
import Logo from "../assets/logo.png";

const START_LOGIN_QUERY = gql`
  query StartLogin($email: String!, $startLoginRequest: String!) {
    startLogin(
      request: { email: $email, startLoginRequest: $startLoginRequest }
    ) {
      loginResponse
      userID
    }
  }
`;

const FINISH_LOGIN_QUERY = gql`
  query FinishLogin($finishLoginRequest: String!, $userID: String!) {
    finishLogin(
      request: { finishLoginRequest: $finishLoginRequest, userID: $userID }
    ) {
      success
    }
  }
`;

function Login() {
  const navigate = useNavigate();
  const [loginError, setLoginError] = useState<string | null>(null);
  const [startLoginQuery] = useLazyQuery(START_LOGIN_QUERY, {
    fetchPolicy: "no-cache",
  });
  const [finishLoginQuery] = useLazyQuery(FINISH_LOGIN_QUERY, {
    fetchPolicy: "no-cache",
  });
  const [email, setEmail] = useState("");

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [password, setPassword] = useState("");
  const completeLogin = useCallback(async () => {
    const { clientLoginState, startLoginRequest } = opaqueClient.startLogin({
      password: password,
    });
    const startLoginVariables: StartLoginRequest = {
      email: email,
      startLoginRequest: startLoginRequest,
    };
    const result: { data: { startLogin: StartLoginResponse } } =
      await startLoginQuery({
        variables: startLoginVariables,
      });
    if (!result.data) {
      setLoginError("Incorrect username or password.");
    }
    const loginResult = opaqueClient.finishLogin({
      clientLoginState,
      loginResponse: result.data.startLogin.loginResponse,
      password,
    });

    if (!loginResult) {
      setLoginError("Incorrect username or password.");
      // TODO: better local storage hygiene
      clearLocalStorage();
      throw new Error("Login failed");
    }

    setExportKey(loginResult.exportKey);

    const finishLoginVariables: FinishLoginRequest = {
      finishLoginRequest: loginResult.finishLoginRequest,
      userID: result.data.startLogin.userID,
    };
    await finishLoginQuery({
      variables: finishLoginVariables,
    });
    navigate("/");
  }, [email, password, startLoginQuery, finishLoginQuery, navigate]);
  return (
    <div className="h-full flex flex-col items-center justify-center">
      <Link to="/">
        <div
          className="absolute top-0 left-0 flex flex-row items-center cursor-pointer"
          style={{ marginLeft: 12, marginTop: 8 }}
        >
          <img src={Logo} alt="logo" width={40} />
          <p
            className="ml-2 font-semibold"
            style={{ fontFamily: "Nothing You Could Do", fontSize: 20 }}
          >
            draft zero
          </p>
        </div>
      </Link>
      <div style={{ width: 300 }}>
        <p className="font-sans">Login</p>
        <div style={{ marginBottom: 10, marginTop: 10 }}>
          <Input
            data-testid="input-email"
            value={email}
            onValueChange={(value) => {
              setEmail(value);
              setLoginError(null);
            }}
            label="email"
            className="font-sans"
            isInvalid={loginError !== null}
          />
        </div>
        <div style={{ marginBottom: 10 }}>
          <Input
            data-testid="input-password"
            type={passwordVisible ? "text" : "password"}
            value={password}
            onValueChange={(value) => {
              setPassword(value);
              setLoginError(null);
            }}
            isInvalid={loginError !== null}
            errorMessage={loginError}
            label="password"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                completeLogin();
              }
            }}
            endContent={
              <Button
                onClick={() => {
                  setPasswordVisible(!passwordVisible);
                }}
                variant="light"
                style={{ minWidth: 0, marginRight: -5 }}
                disableRipple={true}
              >
                {passwordVisible ? <EyeSlash size={24} /> : <Eye size={24} />}
              </Button>
            }
            className="font-sans"
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Button className="font-sans">
              <Link to="/signup">Create account instead</Link>
            </Button>
          </div>
          <Button
            color="primary"
            onClick={async () => {
              await completeLogin();
            }}
            className="font-sans"
            data-testid="button-login"
          >
            Login
          </Button>
        </div>
        <div className="flex flex-row justify-center" style={{ marginTop: 20 }}>
          <Link to="/recover-account">
            <p
              className="font-sans"
              style={{
                color: "gray",
                textDecoration: "underline",
                fontSize: 12,
              }}
            >
              Forgot password?
            </p>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Login;
