import { gql, useMutation, useQuery } from "@apollo/client";
import {
  Avatar,
  Button,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@nextui-org/react";
import { Notification, User, UserAdd } from "iconsax-react";
import { useEffect, useState } from "react";
import { FriendRequestStatus } from "../gql/graphql";
import { useNavigate } from "react-router-dom";
import ProfileAvatar from "./ProfileAvatar";

const GET_PENDING_FRIEND_REQUESTS = gql`
  query GetPendingFriendRequests {
    getPendingFriendRequests {
      username
      userID
      profilePhotoURL
      status
      viewedAt
      profilePlaceholder
    }
  }
`;

const ACCEPT_FRIEND_REQUEST = gql`
  mutation AcceptFriendRequest($username: String!) {
    acceptFriendRequest(request: { username: $username }) {
      success
    }
  }
`;

const DECLINE_FRIEND_REQUEST = gql`
  mutation DeclineFriendRequest($username: String!) {
    declineFriendRequest(request: { username: $username }) {
      success
    }
  }
`;

const VIEW_FRIEND_REQUEST = gql`
  mutation ViewFriendRequest {
    viewFriendRequest {
      success
    }
  }
`;

export default function FriendRequestList() {
  const [pendingFriendRequests, setPendingFriendRequests] = useState<
    {
      username: string;
      userID: string;
      profilePhotoURL: string;
      status: FriendRequestStatus;
      viewedAt: Date | null;
      profilePlaceholder: string;
    }[]
  >([]);
  const navigate = useNavigate();
  const { data, loading } = useQuery<{
    getPendingFriendRequests: {
      username: string;
      userID: string;
      profilePhotoURL: string;
      status: FriendRequestStatus;
      viewedAt: Date | null;
      profilePlaceholder: string;
    }[];
  }>(GET_PENDING_FRIEND_REQUESTS, {
    fetchPolicy: "no-cache",
  });
  useEffect(() => {
    if (data) {
      setPendingFriendRequests(data.getPendingFriendRequests);
    }
  }, [data]);
  const [acceptFriendRequest] = useMutation(ACCEPT_FRIEND_REQUEST);
  const [declineFriendRequest] = useMutation(DECLINE_FRIEND_REQUEST);
  const [viewFriendRequest] = useMutation(VIEW_FRIEND_REQUEST);
  return (
    <div style={{ marginRight: 20 }}>
      <Popover
        onOpenChange={async (isOpen) => {
          if (isOpen) {
            await viewFriendRequest();
          } else {
            setPendingFriendRequests(
              pendingFriendRequests.map((r) => ({
                ...r,
                viewedAt: new Date(),
              }))
            );
          }
        }}
      >
        <PopoverTrigger style={{ cursor: "pointer", position: "relative" }}>
          <div style={{ position: "relative" }}>
            <Notification
              color={
                pendingFriendRequests.filter((r) => !r.viewedAt).length > 0
                  ? "red"
                  : "black"
              }
            />
            {pendingFriendRequests.filter((r) => !r.viewedAt).length > 0 && (
              <div
                style={{
                  position: "absolute",
                  top: -4,
                  right: -4,
                  zIndex: 100,
                  backgroundColor: "red",
                  color: "white",
                  borderRadius: "50%",
                  width: 15,
                  height: 15,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: 10,
                  fontWeight: "bold",
                }}
              >
                {pendingFriendRequests.filter((r) => !r.viewedAt).length}
              </div>
            )}
          </div>
        </PopoverTrigger>
        <PopoverContent>
          <div>
            {pendingFriendRequests.map((request) => (
              <div
                key={request.userID}
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 10,
                  padding: 10,
                }}
                onClick={() => {
                  navigate(`/writer/${request.username}`);
                }}
              >
                <ProfileAvatar
                  src={request.profilePhotoURL}
                  style={{ height: 40, width: 40 }}
                  profilePlaceholder={request.profilePlaceholder}
                />
                <div>
                  <p className="font-sans" style={{ fontWeight: "bold" }}>
                    {request.username}
                  </p>
                  <div style={{ marginTop: 6 }}>
                    {request.status === FriendRequestStatus.Pending && (
                      <div>
                        <Button
                          className="font-sans"
                          style={{ height: 30, fontSize: 12, marginRight: 6 }}
                          onClick={async () => {
                            await acceptFriendRequest({
                              variables: { username: request.username },
                            });
                            setPendingFriendRequests(
                              pendingFriendRequests.map((r) => {
                                if (r.username === request.username) {
                                  return {
                                    ...r,
                                    status: FriendRequestStatus.Accepted,
                                    viewedAt: new Date(),
                                  };
                                }
                                return r;
                              })
                            );
                          }}
                        >
                          Accept
                        </Button>
                        <Button
                          className="font-sans"
                          style={{ height: 30, fontSize: 12 }}
                          onClick={async () => {
                            await declineFriendRequest({
                              variables: { username: request.username },
                            });
                            setPendingFriendRequests(
                              pendingFriendRequests.filter(
                                (r) => r.username !== request.username
                              )
                            );
                          }}
                        >
                          Reject
                        </Button>
                      </div>
                    )}
                    {request.status === FriendRequestStatus.Accepted && (
                      <div
                        className="flex flex-row items-center"
                        style={{ width: 166, height: 30 }}
                      >
                        <p className="font-sans">You are now friends</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
            {pendingFriendRequests.length === 0 && (
              <div style={{ padding: 10 }}>
                <p className="font-sans">No pending friend requests</p>
              </div>
            )}
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
}
