import { gql, useMutation, useQuery } from "@apollo/client";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Message, RoomPresets } from "../gql/graphql";
import EarthBackground from "../assets/earth-background.png";
import Logo from "../assets/logo.png";
import { useDebouncedCallback } from "use-debounce";
import { useUserInfo } from "../hooks/useUserInfo";
import InviteButton from "./InviteButton";
import RoomTimerNew from "./RoomTimerNew";
import { Socket } from "socket.io-client";
import { getUnauthedUserHash } from "../utils/unauthedLocalStorage";
import PromptEditorNew from "./PromptEditorNew";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
} from "@nextui-org/react";
import { ArrowLeft2, ArrowUp2 } from "iconsax-react";
import ProfileAvatar from "./ProfileAvatar";
import { useInterval } from "../hooks/useInterval";
import { Avatar, Input } from "@nextui-org/react";
import WordAttributionBar from "./WordAttributionBar";

const GET_ROOM_DATA = gql`
  query getRoomDataDesktopRoomPanel($roomID: String!) {
    getRoomData(request: { roomID: $roomID }) {
      roomID
      prompt
      timeLeftSeconds
      imageDownloadURL
      name
      ownerID
      presetID
      promptHidden
      timeLeftSeconds
      endTime
      wordsWritten
      scheduledStartTime
      usernameWordList
    }
  }
`;

const GET_MESSAGES_FOR_ROOM = gql`
  query GetMessagesForRoomFooter($roomID: String!) {
    getMessagesForRoom(request: { roomID: $roomID }) {
      content
      messageID
      createdAt
      username
    }
  }
`;

const UPDATE_ROOM_NAME = gql`
  mutation UpdateRoomName($name: String!, $roomID: String!) {
    updateRoomName(request: { name: $name, roomID: $roomID }) {
      success
    }
  }
`;

type RoomState = {
  usersInRoom: {
    username: string;
    profilePhotoDownloadURL: string | null;
    profilePlaceholder?: string;
  }[];
  wordAttributionBar: string[];
  usernameColorMap: { [username: string]: string };
};

const COLOR_DURATION = 200;
export default function DesktopRoomPanel({
  socket,
}: {
  socket: Socket | null;
}) {
  const { roomID } = useParams();

  const { userID, randomUsername, username, settled } = useUserInfo();

  const [joinedRoom, setJoinedRoom] = useState(false);

  const [maxNumAvatars, setMaxNumAvatars] = useState(6);

  const [roomName, setRoomName] = useState<string | null>(null);
  const [promptHidden, setPromptHidden] = useState(false);
  const [messages, setMessages] = useState<Message[]>([]);
  const [isChatOpen, setIsChatOpen] = useState(false);

  const [userListExpanded, setUserListExpanded] = useState(false);
  const [newChatMessage, setNewChatMessage] = useState(false);
  const [hoveredReturnToChat, setHoveredReturnToChat] = useState(false);
  const [hoveredUsername, setHoveredUsername] = useState<string | null>(null);
  const [chatBarHovered, setChatBarHovered] = useState(false);
  const [roomState, setRoomState] = useState<RoomState>({
    usersInRoom: [],
    wordAttributionBar: [],
    usernameColorMap: {},
  });
  const scrollViewRef = useRef<HTMLDivElement>(null);
  const [messageInput, setMessageInput] = useState("");
  const [hoveredExpandedUserRow, setHoveredExpandedUserRow] = useState<
    string | null
  >(null);

  const [updateRoomName] = useMutation(UPDATE_ROOM_NAME);

  const debouncedUpdateRoomName = useDebouncedCallback(updateRoomName, 300);

  // initial fetch of messages
  useQuery(GET_MESSAGES_FOR_ROOM, {
    variables: { roomID },
    onCompleted: (data) => {
      setMessages(data.getMessagesForRoom);
    },
    fetchPolicy: "no-cache",
  });

  const { data: roomData, refetch: refetchRoomData } = useQuery(GET_ROOM_DATA, {
    variables: { roomID },
    skip: !roomID,
    onCompleted: (data) => {
      setRoomName(data.getRoomData.name);
      setPromptHidden(data.getRoomData.promptHidden);
      setWordsWritten(data.getRoomData.wordsWritten);
    },
    fetchPolicy: "no-cache",
  });

  const sendMessage = useCallback(() => {
    if (socket) {
      socket.emit("send_chat_message", {
        message: messageInput,
        roomID,
        userID,
      });
      setMessageInput("");
    }
  }, [messageInput, roomID, socket, userID]);

  const isRoomOwner = useMemo(() => {
    return userID === roomData?.getRoomData?.ownerID;
  }, [userID, roomData?.getRoomData?.ownerID]);

  const isPresetRoom = useMemo(() => {
    return !!roomData?.getRoomData?.presetID;
  }, [roomData?.getRoomData?.presetID]);

  const logoSrc = useMemo(() => {
    if (roomID === RoomPresets.TheEveryoneRoom) {
      return EarthBackground;
    }
    return roomData?.getRoomData?.imageDownloadURL || Logo;
  }, [roomData?.getRoomData?.imageDownloadURL, roomID]);

  useEffect(() => {
    if (socket) {
      socket.on("disconnect", () => {
        setJoinedRoom(false);
      });
      if (!joinedRoom && settled) {
        if (userID) {
          socket.emit("user_room_join", { roomID, userID, username });
          setJoinedRoom(true);
        } else {
          const unauthedUserHash = getUnauthedUserHash();
          socket.emit("user_room_join", {
            roomID,
            userID: `guest-${unauthedUserHash}`,
            username: randomUsername,
            isGuest: true,
          });
          setJoinedRoom(true);
        }
      }
    }
  }, [joinedRoom, randomUsername, roomID, socket, userID, settled, username]);
  const scrollWindowRef = useRef<HTMLDivElement>(null);
  const scrollToBottom = useCallback(() => {
    if (scrollWindowRef.current) {
      scrollWindowRef.current.scrollTop = scrollWindowRef.current.scrollHeight;
    }
  }, []);

  const showChatMessages = useMemo(
    () => isChatOpen && !userListExpanded,
    [isChatOpen, userListExpanded]
  );

  const [wordsWritten, setWordsWritten] = useState(0);

  useEffect(() => {
    if (socket) {
      if (!socket.hasListeners("new_message")) {
        socket.on("new_message", (...args) => {
          setMessages((prevMessages) => [...prevMessages, args[0]]);
          if (showChatMessages) {
            setTimeout(scrollToBottom, 10);
          } else {
            setNewChatMessage(true);
          }
        });
      }
    }
    return () => {
      if (socket) {
        socket.off("new_message");
      }
    };
  }, [socket, scrollToBottom, showChatMessages]);

  useEffect(() => {
    if (showChatMessages) {
      scrollToBottom();
    }
  }, [showChatMessages, scrollToBottom]);
  const [wordsAddedUsers, setWordsAddedUsers] = useState<{
    [userID: string]: Date;
  }>({});
  const [wordsDeletedUsers, setWordsDeletedUsers] = useState<{
    [userID: string]: Date;
  }>({});
  const [currentDate, setCurrentDate] = useState(new Date());
  useInterval(() => {
    setCurrentDate(new Date());
  }, 200);

  const computeBorderColor = useCallback(
    (username: string) => {
      if (
        wordsAddedUsers[username] &&
        currentDate.getTime() - wordsAddedUsers[username].getTime() <
          COLOR_DURATION
      ) {
        // return green with opacity depending on time elapsed
        // should fade from green to transparent over 2 seconds
        return "#53bf79";
        // return greenColorMap(
        //   (Date.now() - wordsAddedUsers[username].getTime()) / 2000
        // );
      } else if (
        wordsDeletedUsers[username] &&
        currentDate.getTime() - wordsDeletedUsers[username].getTime() <
          COLOR_DURATION
      ) {
        return "#ff6b6b";
        // return redColorMap(
        //   (Date.now() - wordsDeletedUsers[username].getTime()) / 2000
        // );
      } else {
        return null;
      }
    },
    [currentDate, wordsAddedUsers, wordsDeletedUsers]
  );

  useEffect(() => {
    if (socket) {
      if (!socket.hasListeners("room_update")) {
        socket.on("room_update", (...args) => {
          if (args[0].type === "room_state") {
            const newUsersInRoom = [];
            const usernamesAlreadyInRoom = new Set();
            for (const user of args[0].usersInRoom) {
              if (user.username === randomUsername) {
                continue;
              }
              if (!usernamesAlreadyInRoom.has(user.username)) {
                newUsersInRoom.push(user);
                usernamesAlreadyInRoom.add(user.username);
              }
            }
            setRoomState({
              ...roomState,
              usersInRoom: newUsersInRoom,
              wordAttributionBar: args[0].wordAttributionBar,
              usernameColorMap: args[0].usernameColorMap,
            });
          } else if (args[0].type === "user_left") {
            setRoomState({
              ...roomState,
              usersInRoom: roomState.usersInRoom.filter(
                (user) => user.username !== args[0].username
              ),
            });
          } else if (args[0].type === "awareness_activity") {
            if (args[0].content === "word_added") {
              const numWordsAdded = (args[0].payload as { wordsAdded: number })
                .wordsAdded;
              const isGuest = (args[0].payload as { isGuest: boolean }).isGuest;
              setWordsAddedUsers({
                ...wordsAddedUsers,
                [args[0].username]: new Date(),
              });
              if (!isGuest) {
                setRoomState({
                  ...roomState,
                  wordAttributionBar: [
                    ...roomState.wordAttributionBar,
                    ...Array(numWordsAdded).fill(args[0].username),
                  ],
                  usernameColorMap: args[0].usernameColorMap,
                });
              }
            } else if (args[0].content === "word_deleted") {
              setWordsDeletedUsers({
                ...wordsDeletedUsers,
                [args[0].username]: new Date(),
              });
            }
          } else if (args[0].type === "words_written") {
            setWordsWritten(args[0].wordsWritten);
          }
        });
      }
    }
    return () => {
      if (socket) {
        socket.off("room_update");
      }
    };
  }, [
    randomUsername,
    roomState,
    roomState.usersInRoom,
    socket,
    username,
    wordsAddedUsers,
    wordsDeletedUsers,
  ]);

  return (
    <div className="flex flex-col w-full h-full relative justify-between">
      <Modal
        isOpen={
          !!roomData?.getRoomData?.scheduledStartTime &&
          new Date(roomData?.getRoomData?.scheduledStartTime) > new Date()
        }
        isDismissable={false}
        hideCloseButton={true}
      >
        <ModalContent>
          <ModalHeader>
            <div className="font-sans">
              <p style={{ fontSize: 24, fontWeight: "bold" }}>
                You're here early!
              </p>
            </div>
          </ModalHeader>
          <ModalBody>
            <div className="font-sans">
              <p style={{ fontSize: 16 }}>
                The writing room will start on{" "}
                {new Date(
                  roomData?.getRoomData?.scheduledStartTime
                ).toLocaleString("en-US", {
                  month: "long",
                  day: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                })}
                .
              </p>
            </div>
            <div className="flex flex-row justify-end">
              <a href="/">
                <Button className="font-sans" color="primary">
                  Go home
                </Button>
              </a>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
      <div className="overflow-y-auto">
        <div
          className="flex flex-row items-center justify-between"
          style={{
            padding: 10,
            borderBottomWidth: 1,
            borderColor: "#E0E0E0",
            borderStyle: "solid",
          }}
        >
          <div className="flex flex-row items-center min-w-0">
            <div className="flex flex-row min-w-0 items-center flex-1">
              <div style={{ marginRight: 10 }}>
                <img
                  src={logoSrc}
                  alt="Logo"
                  style={{
                    minWidth: "40px",
                    width: "40px",
                    height: "40px",
                    objectFit: "cover",
                    objectPosition: "center",
                    borderRadius: 10,
                  }}
                />
              </div>
              <div className="min-w-0 flex-1">
                {roomName !== null && (
                  <div className="inline-block relative w-full">
                    <input
                      type="text"
                      placeholder="Room name"
                      value={roomName}
                      readOnly={!isRoomOwner || isPresetRoom}
                      onChange={(e) => {
                        setRoomName(e.target.value);
                        debouncedUpdateRoomName({
                          variables: {
                            name: e.target.value,
                            roomID,
                          },
                        });
                      }}
                      style={{
                        color: "#456577",
                        width: "100%",
                        textOverflow: "ellipsis",
                      }}
                      maxLength={70}
                      className="font-sans font-semibold text-lg bg-transparent shadow-none outline-none"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div>
            <InviteButton />
          </div>
        </div>
        <div
          className="flex flex-row items-center justify-between"
          style={{
            padding: 10,
            borderBottomWidth: 1,
            borderColor: "#E0E0E0",
            borderStyle: "solid",
          }}
        >
          <div>
            <p className="font-sans">Words written in room</p>
          </div>
          <div>
            <p className="font-sans">{wordsWritten.toLocaleString()}</p>
          </div>
        </div>
        <div
          className="flex flex-row items-center justify-between"
          style={{
            padding: 10,
            borderBottomWidth: 1,
            borderColor: "#E0E0E0",
            borderStyle: "solid",
          }}
        >
          <div>
            <p className="font-sans">Timer</p>
          </div>
          <div>
            <RoomTimerNew socket={socket} roomData={roomData?.getRoomData} />
          </div>
        </div>
        {!promptHidden && (
          <div
            className="flex flex-row items-center justify-between"
            style={{
              padding: 10,
              borderBottomWidth: 1,
              borderColor: "#E0E0E0",
              borderStyle: "solid",
            }}
          >
            <div className="w-full">
              <PromptEditorNew
                socket={socket}
                setPromptHidden={setPromptHidden}
              />
            </div>
          </div>
        )}
      </div>
      <div className="flex-1 flex flex-col justify-end">
        <WordAttributionBar
          usernameWordList={roomState.wordAttributionBar}
          usernameColorMap={roomState.usernameColorMap}
        />
        <div style={{ zIndex: 10, position: "relative" }}>
          <div
            className={`flex flex-row items-center border border-gray-400 ${
              userListExpanded ? "justify-between" : "justify-end"
            }`}
            onMouseEnter={() => {
              setChatBarHovered(true);
            }}
            onMouseLeave={() => {
              setChatBarHovered(false);
            }}
            onClick={() => {
              if (!isChatOpen) {
                setNewChatMessage(false);
              }
              setIsChatOpen(!isChatOpen);
              setUserListExpanded(false);
              setHoveredReturnToChat(false);
              setHoveredUsername(null);
            }}
            style={{
              backgroundColor: chatBarHovered ? "#f7fafc" : "white",
              cursor: "pointer",
              height: 30,
            }}
          >
            <div className="flex flex-row items-center">
              {userListExpanded && (
                <div
                  className="flex flex-row items-center"
                  style={{
                    marginRight: 8,
                    padding: 4,
                    backgroundColor: hoveredReturnToChat
                      ? "black"
                      : "transparent",
                  }}
                  onMouseEnter={() => {
                    setHoveredReturnToChat(true);
                  }}
                  onMouseLeave={() => {
                    setHoveredReturnToChat(false);
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setHoveredReturnToChat(false);
                    setUserListExpanded(false);
                    setNewChatMessage(false);
                  }}
                >
                  <ArrowLeft2
                    size={12}
                    color={hoveredReturnToChat ? "white" : "black"}
                  />
                  <p
                    className="font-sans"
                    style={{
                      fontSize: 12,
                      color: hoveredReturnToChat ? "white" : "black",
                    }}
                  >
                    chat
                  </p>
                </div>
              )}
              {newChatMessage && (
                <div
                  style={{
                    backgroundColor: "#456577",
                    width: 5,
                    height: 5,
                    borderRadius: 5,
                    marginRight: 8,
                  }}
                />
              )}
            </div>

            <div className="flex flex-row items-center" style={{ padding: 4 }}>
              {!userListExpanded &&
                roomState.usersInRoom
                  .slice(0, maxNumAvatars)
                  .map(
                    ({
                      profilePhotoDownloadURL,
                      username,
                      profilePlaceholder,
                    }) => (
                      <div
                        className="flex flex-col items-center relative"
                        key={`room_user_${username}`}
                        style={{ marginRight: 4 }}
                      >
                        <ProfileAvatar
                          src={profilePhotoDownloadURL ?? ""}
                          profilePlaceholder={profilePlaceholder}
                          isBordered={!!computeBorderColor(username)}
                          style={{
                            transitionProperty: "outline-color",
                            transitionDuration: "0.2s",
                            outlineColor:
                              computeBorderColor(username) ?? undefined,
                            height: 20,
                            width: 20,
                          }}
                          onMouseEnter={() => {
                            setHoveredUsername(username);
                          }}
                          onMouseLeave={() => {
                            setHoveredUsername(null);
                          }}
                          onClick={(e: MouseEvent) => {
                            if (isChatOpen) {
                              e.stopPropagation();
                              setUserListExpanded(true);
                            }
                          }}
                        />
                        {hoveredUsername === username && (
                          <div
                            className="absolute"
                            style={{
                              backgroundColor: "white",
                              right: 0,
                              bottom: 20,
                            }}
                          >
                            <p style={{ fontSize: 12, color: "gray" }}>
                              {username}
                            </p>
                          </div>
                        )}
                      </div>
                    )
                  )}
              {!userListExpanded &&
                roomState.usersInRoom.length > maxNumAvatars && (
                  <div className="font-sans">
                    <Avatar
                      name={`+${roomState.usersInRoom.length - maxNumAvatars}`}
                      showFallback
                      style={{ height: 20, width: 20 }}
                      onClick={(e) => {
                        if (isChatOpen) {
                          e.stopPropagation();
                          setUserListExpanded(!userListExpanded);
                        }
                      }}
                    />
                  </div>
                )}
              <div className="flex items-center ml-2">
                <ArrowUp2
                  size={16}
                  color="#718096"
                  className={`transition-transform duration-300 ${
                    isChatOpen ? "rotate-180" : ""
                  }`}
                />
              </div>
            </div>
          </div>
          {userListExpanded && (
            <div
              className="flex flex-col border border-gray-400"
              style={{
                background: "white",
                padding: 4,
                maxHeight: 348,
                overflowY: "auto",
              }}
            >
              {roomState.usersInRoom.map((user) => (
                <div
                  className="flex flex-row items-center cursor-pointer"
                  key={`user_${user.username}`}
                  style={{
                    padding: 4,
                    backgroundColor:
                      hoveredExpandedUserRow === user.username
                        ? "#f7fafc"
                        : "white",
                  }}
                  onClick={() => {
                    window.open(`/writer/${user.username}`, "_blank");
                  }}
                  onMouseEnter={() => {
                    setHoveredExpandedUserRow(user.username);
                  }}
                  onMouseLeave={() => {
                    setHoveredExpandedUserRow(null);
                  }}
                >
                  <div style={{ marginRight: 4 }}>
                    <ProfileAvatar
                      src={user.profilePhotoDownloadURL ?? ""}
                      size="sm"
                      profilePlaceholder={user.profilePlaceholder}
                      style={{ height: 20, width: 20 }}
                    />
                  </div>
                  <p className="font-sans" style={{ fontSize: 12 }}>
                    {user.username}
                  </p>
                </div>
              ))}
            </div>
          )}
          <div
            className={`flex-1 bg-white flex flex-col ${
              showChatMessages ? "border border-gray-400" : ""
            } transition-all duration-300 ease-in-out ${
              showChatMessages ? "w-[280px]" : "w-0"
            }`}
            style={{
              marginBottom: 0,
              marginTop: 0,
              marginLeft: isChatOpen ? 0 : 20,
              display: showChatMessages ? "block" : "none",
            }}
          >
            <div
              className="flex-1"
              ref={scrollWindowRef}
              style={{
                maxHeight: 300,
                minHeight: 50,
                overflowY: "auto",
              }}
            >
              <div
                className="flex-1 flex flex-col justify-end"
                style={{ padding: 20 }}
              >
                {messages.map((message) => (
                  <div
                    key={`message_${message.messageID}`}
                    style={{ marginBottom: 10 }}
                  >
                    <div className="font-sans flex flex-row justify-between items-center">
                      <a
                        href={`/writer/${message.username}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <p style={{ fontSize: 12, fontWeight: "bold" }}>
                          {message.username}
                        </p>
                      </a>
                      <p
                        className="font-mono"
                        style={{
                          fontSize: 12,
                          textAlign: "right",
                        }}
                      >
                        {new Date(message.createdAt).toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                      </p>
                    </div>
                    <div
                      className="flex flex-col"
                      style={{
                        width: "100%",
                      }}
                    >
                      <p
                        className="font-sans"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {message.content}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
              <div ref={scrollViewRef} />
            </div>
            <div
              className="flex"
              style={{ paddingLeft: 16, paddingRight: 16, paddingBottom: 16 }}
            >
              <Input
                placeholder="Chat here"
                classNames={{
                  inputWrapper: ["h-[24px] font-sans"],
                }}
                variant="underlined"
                size="sm"
                style={{ fontSize: 16 }}
                onValueChange={(value) => {
                  setMessageInput(value);
                }}
                value={messageInput}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && messageInput.trim()) {
                    sendMessage();
                  }
                }}
              />

              <div style={{ marginLeft: 16 }}>
                <Button
                  size="sm"
                  className="font-sans"
                  onClick={sendMessage}
                  isDisabled={!messageInput.trim()}
                >
                  Send
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
