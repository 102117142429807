import { gql, useMutation, useQuery } from "@apollo/client";
import { Button } from "@nextui-org/react";
import { Pause, Play } from "iconsax-react";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useInterval } from "../hooks/useInterval";
import { Socket } from "socket.io-client";
import { GetRoomDataQuery, RoomPresets } from "../gql/graphql";

const GET_ROOM_DATA = gql`
  query GetRoomData($roomID: String!) {
    getRoomData(request: { roomID: $roomID }) {
      timeLeftSeconds
      endTime
    }
  }
`;

const UPDATE_ROOM_TIMER = gql`
  mutation UpdateRoomTimer(
    $timeLeftSeconds: Int
    $endTime: DateTime
    $roomID: String!
  ) {
    updateRoomTimer(
      request: {
        timeLeftSeconds: $timeLeftSeconds
        endTime: $endTime
        roomID: $roomID
      }
    ) {
      success
    }
  }
`;

export default function RoomTimerNew({
  socket,
  roomData,
}: {
  socket: Socket | null;
  roomData: GetRoomDataQuery["getRoomData"] | null;
}) {
  const { roomID } = useParams();

  const [endTime, setEndTime] = useState<Date | null>(null);
  const [timeLeftSeconds, setTimeLeftSeconds] = useState<number>(0);
  const [editingField, setEditingField] = useState<
    "hours" | "minutes" | "seconds" | null
  >(null);
  const [displayTimeLeftHours, setDisplayTimeLeftHours] = useState<number>(0);
  const [displayTimeLeftMinutes, setDisplayTimeLeftMinutes] =
    useState<number>(0);
  const [displayTimeLeftSeconds, setDisplayTimeLeftSeconds] =
    useState<number>(0);
  const [timerExpired, setTimerExpired] = useState<boolean>(false);
  const [timerExpiredColor, setTimerExpiredColor] =
    useState<string>("rgb(242, 17, 97)");

  useEffect(() => {
    if (roomData) {
      if (roomData.endTime) {
        const endTime = new Date(roomData.endTime);
        setEndTime(endTime);
        const timeMillisLeft = endTime.getTime() - new Date().getTime();

        const timeSecondsLeft = Math.ceil(timeMillisLeft / 1000);
        setTimeLeftSeconds(timeSecondsLeft);
        setDisplayTimeLeftHours(Math.floor(timeSecondsLeft / 3600));
        setDisplayTimeLeftMinutes(Math.floor((timeSecondsLeft % 3600) / 60));
        setDisplayTimeLeftSeconds(timeSecondsLeft % 60);
      } else {
        const timeLeftSeconds = roomData?.timeLeftSeconds || 0;
        setTimeLeftSeconds(timeLeftSeconds || 0);
        setDisplayTimeLeftHours(Math.floor(timeLeftSeconds / 3600));
        setDisplayTimeLeftMinutes(Math.floor((timeLeftSeconds % 3600) / 60));
        setDisplayTimeLeftSeconds(timeLeftSeconds % 60);
      }
    }
  }, [roomData]);

  useEffect(() => {
    if (socket && !socket.hasListeners("room_timer_update")) {
      socket.on("room_timer_update", (...args) => {
        if (args[0].timeLeftSeconds !== null) {
          setTimeLeftSeconds(args[0].timeLeftSeconds);
          setDisplayTimeLeftHours(Math.floor(args[0].timeLeftSeconds / 3600));
          setDisplayTimeLeftMinutes(
            Math.floor((args[0].timeLeftSeconds % 3600) / 60)
          );
          setDisplayTimeLeftSeconds(args[0].timeLeftSeconds % 60);
        }

        setEndTime(args[0].endTime ? new Date(args[0].endTime) : null);
      });
    }
  }, [socket]);

  const [updateRoomTimer] = useMutation(UPDATE_ROOM_TIMER, {
    onCompleted: () => {
      socket?.emit("room_timer_update", {
        timeLeftSeconds,
        endTime: endTime?.toISOString(),
        roomID,
      });
    },
  });

  const isPaused = useMemo(() => {
    return !endTime;
  }, [endTime]);

  useInterval(() => {
    if (timerExpiredColor === "rgb(242, 17, 97)") {
      setTimerExpiredColor("black");
    } else {
      setTimerExpiredColor("rgb(242, 17, 97)");
    }
  }, 700);

  useInterval(
    async () => {
      if (!endTime) return;
      if (new Date().getTime() > endTime.getTime()) {
        setTimerExpired(true);
        setEndTime(null);
        setTimeLeftSeconds(0);
        setDisplayTimeLeftHours(0);
        setDisplayTimeLeftMinutes(0);
        setDisplayTimeLeftSeconds(0);
        await updateRoomTimer({
          variables: {
            endTime: null,
            timeLeftSeconds: 0,
            roomID,
          },
        });
      } else {
        const timeMillisLeft = endTime.getTime() - new Date().getTime();
        const timeSecondsLeft = Math.ceil(timeMillisLeft / 1000);
        setTimeLeftSeconds(timeSecondsLeft);
        setDisplayTimeLeftHours(Math.floor(timeSecondsLeft / 3600));
        setDisplayTimeLeftMinutes(Math.floor((timeSecondsLeft % 3600) / 60));
        setDisplayTimeLeftSeconds(timeSecondsLeft % 60);
      }
    },
    endTime ? 1000 : null
  );

  if (!roomData) return null;

  return (
    <div className="flex flex-row items-center">
      <Button
        variant="light"
        isIconOnly
        isDisabled={timeLeftSeconds === 0}
        onClick={async () => {
          if (isPaused) {
            const newEndTime = new Date(Date.now() + timeLeftSeconds * 1000);
            setEndTime(newEndTime);
            await updateRoomTimer({
              variables: {
                endTime: newEndTime,
                roomID,
              },
            });
          } else {
            setEndTime(null);
            await updateRoomTimer({
              variables: {
                endTime: null,
                timeLeftSeconds: timeLeftSeconds,
                roomID,
              },
            });
          }
        }}
        size="sm"
        style={{ color: isPaused ? "#7194A8" : "#456577" }}
      >
        {isPaused ? <Play size={16} /> : <Pause size={16} />}
      </Button>
      <div
        className="flex items-center"
        style={{
          color: timerExpired
            ? timerExpiredColor
            : isPaused
            ? "#7194A8"
            : "#456577",
        }}
      >
        <input
          readOnly={!isPaused}
          onFocus={() => {
            setEditingField("hours");
            setTimerExpired(false);
          }}
          maxLength={2}
          onBlur={async () => {
            setEditingField(null);
            if (displayTimeLeftHours === -1) {
              setDisplayTimeLeftHours(0);
            }
            const correctedHours =
              displayTimeLeftHours >= 0 ? displayTimeLeftHours : 0;
            const newTimeLeftSeconds =
              correctedHours * 3600 +
              displayTimeLeftMinutes * 60 +
              displayTimeLeftSeconds;
            setTimeLeftSeconds(newTimeLeftSeconds);
            setDisplayTimeLeftHours(Math.floor(newTimeLeftSeconds / 3600));
            setDisplayTimeLeftMinutes(
              Math.floor((newTimeLeftSeconds % 3600) / 60)
            );
            setDisplayTimeLeftSeconds(newTimeLeftSeconds % 60);
            await updateRoomTimer({
              variables: {
                timeLeftSeconds: newTimeLeftSeconds,
                roomID,
              },
            });
          }}
          onChange={(e) => {
            setDisplayTimeLeftHours(
              e.target.value === "" ? -1 : parseInt(e.target.value.slice(0, 2))
            );
          }}
          type={editingField !== "hours" ? "text" : "number"}
          value={
            editingField !== "hours"
              ? displayTimeLeftHours?.toString().padStart(2, "0") || "00"
              : displayTimeLeftHours >= 0
              ? displayTimeLeftHours
              : ""
          }
          className="w-6 bg-transparent font-mono leading-none"
          style={{
            outline: isPaused ? undefined : "none",
            outlineColor: timerExpired ? timerExpiredColor : "#7194A8",
            fontSize: 20,
            paddingTop: 4,
            cursor: isPaused ? undefined : "default",
          }}
        />
        <span
          className="inline-flex items-center justify-center font-mono leading-none"
          style={{ cursor: isPaused ? undefined : "default" }}
        >
          :
        </span>
        <input
          readOnly={!isPaused}
          maxLength={2}
          onFocus={() => {
            setEditingField("minutes");
            setTimerExpired(false);
          }}
          onBlur={async () => {
            setEditingField(null);
            if (displayTimeLeftMinutes === -1) {
              setDisplayTimeLeftMinutes(0);
            }
            const correctedMinutes =
              displayTimeLeftMinutes >= 0 ? displayTimeLeftMinutes : 0;
            const newTimeLeftSeconds =
              displayTimeLeftHours * 3600 +
              correctedMinutes * 60 +
              displayTimeLeftSeconds;
            setTimeLeftSeconds(newTimeLeftSeconds);
            setDisplayTimeLeftHours(Math.floor(newTimeLeftSeconds / 3600));
            setDisplayTimeLeftMinutes(
              Math.floor((newTimeLeftSeconds % 3600) / 60)
            );
            setDisplayTimeLeftSeconds(newTimeLeftSeconds % 60);
            await updateRoomTimer({
              variables: {
                timeLeftSeconds: newTimeLeftSeconds,
                roomID,
              },
            });
          }}
          onChange={(e) => {
            setDisplayTimeLeftMinutes(
              e.target.value === "" ? -1 : parseInt(e.target.value.slice(0, 2))
            );
          }}
          type={editingField !== "minutes" ? "text" : "number"}
          value={
            editingField !== "minutes"
              ? displayTimeLeftMinutes?.toString().padStart(2, "0") || "00"
              : displayTimeLeftMinutes >= 0
              ? displayTimeLeftMinutes
              : ""
          }
          className="w-6 bg-transparent text-center font-mono leading-none"
          style={{
            outline: isPaused ? undefined : "none",
            outlineColor: timerExpired ? timerExpiredColor : "#7194A8",
            fontSize: 20,
            paddingTop: 4,
            cursor: isPaused ? undefined : "default",
          }}
        />
        <span
          className="inline-flex items-center justify-center font-mono leading-none"
          style={{ cursor: isPaused ? undefined : "default" }}
        >
          :
        </span>
        <input
          readOnly={!isPaused}
          maxLength={2}
          onFocus={() => {
            setEditingField("seconds");
            setTimerExpired(false);
          }}
          onBlur={async () => {
            setEditingField(null);
            if (displayTimeLeftSeconds === -1) {
              setDisplayTimeLeftSeconds(0);
            }
            const correctedSeconds =
              displayTimeLeftSeconds >= 0 ? displayTimeLeftSeconds : 0;
            const newTimeLeftSeconds =
              displayTimeLeftHours * 3600 +
              displayTimeLeftMinutes * 60 +
              correctedSeconds;
            setTimeLeftSeconds(newTimeLeftSeconds);
            setDisplayTimeLeftHours(Math.floor(newTimeLeftSeconds / 3600));
            setDisplayTimeLeftMinutes(
              Math.floor((newTimeLeftSeconds % 3600) / 60)
            );
            setDisplayTimeLeftSeconds(newTimeLeftSeconds % 60);
            await updateRoomTimer({
              variables: {
                timeLeftSeconds: newTimeLeftSeconds,
                roomID,
              },
            });
          }}
          onChange={(e) => {
            setDisplayTimeLeftSeconds(
              e.target.value === "" ? -1 : parseInt(e.target.value.slice(0, 2))
            );
          }}
          type={editingField !== "seconds" ? "text" : "number"}
          value={
            editingField !== "seconds"
              ? displayTimeLeftSeconds?.toString().padStart(2, "0") || "00"
              : displayTimeLeftSeconds >= 0
              ? displayTimeLeftSeconds
              : ""
          }
          className="w-6 bg-transparent text-center font-mono leading-none"
          style={{
            outline: isPaused ? undefined : "none",
            outlineColor: timerExpired ? timerExpiredColor : "#7194A8",
            fontSize: 20,
            paddingTop: 4,
            cursor: isPaused ? undefined : "default",
          }}
        />
      </div>
    </div>
  );
}
