import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./userStore";
import draftReducer from "./draftStore";
import activityReducer from "./activityStore";
// ...

export const store = configureStore({
  reducer: {
    user: userReducer,
    draft: draftReducer,
    activity: activityReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
