import { Extension } from "@tiptap/core";

export const LiteralTab = Extension.create({
  name: "literalTab",

  addKeyboardShortcuts() {
    return {
      Tab: () => {
        if (
          this.editor.isActive("bulletList") ||
          this.editor.isActive("orderedList")
        ) {
          this.editor.chain().sinkListItem("listItem").run();
        } else {
          this.editor.commands.insertContent("\t");
        }
        // return true to prevent the default browser behavior
        return true;
      },
      "Shift-Tab": () => {
        if (
          this.editor.isActive("bulletList") ||
          this.editor.isActive("orderedList")
        ) {
          this.editor.chain().liftListItem("listItem").run();
        }
        // return true to prevent the default browser behavior
        return true;
      },
    };
  },
});
