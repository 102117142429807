import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownSection,
  DropdownTrigger,
  Input,
  Spinner,
} from "@nextui-org/react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  decryptSymmetricString,
  encryptSymmetricString,
} from "../crypto/utils";
import { useUserInfo } from "../hooks/useUserInfo";
import { useNavigate, useParams } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import { useDispatch, useSelector } from "react-redux";
import {
  setDraftTitle as reduxSetDraftTitle,
  setOutOfSync,
  setTitleChecksum,
} from "../redux/draftStore";
import { RootState } from "../redux/store";
import Logo from "../assets/logo.png";
import EarthBackground from "../assets/earth-background.png";
import { DraftMetadata as gqlDraftMetadata, RoomPresets } from "../gql/graphql";
import {
  Document,
  DocumentDownload,
  Eye,
  EyeSlash,
  Home3,
  Trash,
} from "iconsax-react";
import DraftSelectModal from "./DraftSelectModal";
import { getDraftPath } from "../utils/urlPaths";
import RoomTimer from "./RoomTimer";
import { Socket } from "socket.io-client";
import ProfileMenu from "./ProfileMenu";
import { asBlob } from "html-docx-js-typescript";
import fileDownload from "js-file-download";
import TurndownService from "turndown";
import FeedbackButton from "./FeedbackButton";
import { useMediaQuery } from "react-responsive";
import RoomPermanenceIndicator from "./RoomPermanenceIndicator";

const UPDATE_DRAFT_TITLE_MUTATION = gql`
  mutation updateEncryptedDraftTitle(
    $draftID: String!
    $encryptedTitle: String!
    $oldTitleChecksum: String
  ) {
    updateTitle(
      request: {
        draftID: $draftID
        encryptedTitle: $encryptedTitle
        oldTitleChecksum: $oldTitleChecksum
      }
    ) {
      newTitleChecksum
    }
  }
`;

enum IconDropdownKeys {
  Home = "Home",
  CreateNew = "CreateNew",
  SelectDraft = "SelectDraft",
  DeleteRoom = "DeleteRoom",
  ExportToDocx = "ExportToDocx",
  ExportToMarkdown = "ExportToMarkdown",
  GeneratePrompt = "GeneratePrompt",
}

const GET_DRAFTS_METADATA = gql`
  query GetDraftsMetadataDocumentEditor($excludeDraftID: String!) {
    getDraftsMetadata(request: { excludeDraftID: $excludeDraftID }) {
      drafts {
        draftID
        encryptedTitle
        updatedAt
      }
      hasMore
    }
  }
`;

const CREATE_DRAFT = gql`
  mutation CreateDraft {
    createDraft
  }
`;

const GET_ROOM_DATA = gql`
  query getRoomDataDocumentEditorHeader($roomID: String!) {
    getRoomData(request: { roomID: $roomID }) {
      roomID
      prompt
      timeLeftSeconds
      imageDownloadURL
      name
      ownerID
      presetID
      promptHidden
    }
  }
`;

const UPDATE_ROOM_PROMPT_HIDDEN = gql`
  mutation UpdateRoomPromptHiddenLogoMenu(
    $roomID: String!
    $promptHidden: Boolean!
  ) {
    updateRoomPromptHidden(
      request: { roomID: $roomID, promptHidden: $promptHidden }
    ) {
      success
    }
  }
`;

const DELETE_ROOM = gql`
  mutation DeleteRoom($roomID: String!) {
    deleteRoom(request: { roomID: $roomID }) {
      success
    }
  }
`;

type DraftMetadata = {
  draftID: string;
  title: string | null;
};

function DocumentEditorHeader({ socket }: { socket: Socket | null }) {
  const { userID, decryptionKey } = useUserInfo();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({
    query: "(max-width: 680px)",
  });

  const { draftID, roomID } = useParams();

  const [createDraft] = useMutation(CREATE_DRAFT);
  const [deleteRoom] = useMutation(DELETE_ROOM);
  const [lastDraftID, setLastDraftID] = useState<string | null>(null);
  const [roomName, setRoomName] = useState<string | null>(null);
  const [promptHidden, setPromptHidden] = useState(false);
  const [exportingToWord, setExportingToWord] = useState(false);
  const [exportingToMarkdown, setExportingToMarkdown] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    if (!lastDraftID && draftID) {
      setLastDraftID(draftID);
    }
  }, [draftID, lastDraftID]);

  // if user is navigating between drafts, blow everything away
  useEffect(() => {
    if (draftID && lastDraftID && draftID !== lastDraftID) {
      navigate(0); // equivalent to window.location.reload()
    }
  }, [draftID, lastDraftID, navigate]);
  const { data, loading } = useQuery(GET_DRAFTS_METADATA, {
    variables: {
      excludeDraftID: draftID,
    },
    fetchPolicy: "no-cache",
  });

  const { data: roomData, refetch: refetchRoomData } = useQuery(GET_ROOM_DATA, {
    variables: { roomID },
    skip: !roomID,
    onCompleted: (data) => {
      setRoomName(data.getRoomData.name);
      setPromptHidden(data.getRoomData.promptHidden);
    },
    fetchPolicy: "no-cache",
  });

  const [updateRoomPromptHidden] = useMutation(UPDATE_ROOM_PROMPT_HIDDEN);

  const isPresetRoom = useMemo(() => {
    return !!roomData?.getRoomData?.presetID;
  }, [roomData?.getRoomData?.presetID]);
  const draftMetadataList = useMemo<DraftMetadata[]>(() => {
    if (!data || !decryptionKey) {
      return [];
    }
    return data.getDraftsMetadata.drafts.map((metadata: gqlDraftMetadata) => {
      return {
        draftID: metadata.draftID,
        title: decryptSymmetricString({
          encryptedPayload: metadata.encryptedTitle,
          secretKey: decryptionKey,
        }),
      };
    });
  }, [data, decryptionKey]);
  const savedDraftTitle = useSelector((state: RootState) => state.draft.title);
  const titleChecksum = useSelector(
    (state: RootState) => state.draft.titleChecksum
  );
  const [updateEncryptedDraftTitle] = useMutation(UPDATE_DRAFT_TITLE_MUTATION, {
    onCompleted: (data) => {
      if (data.updateTitle) {
        dispatch(setTitleChecksum(data.updateTitle.newTitleChecksum));
      }
    },
    onError: (e) => {
      const errorCodes = e.graphQLErrors.map((error) => error.extensions?.code);
      if (errorCodes.includes("CHECKSUM_MISMATCH")) {
        dispatch(setOutOfSync());
      }
    },
  });
  const [draftTitle, setDraftTitle] = useState("");
  const [allDraftsModalOpen, setAllDraftsModalOpen] = useState(false);
  const draftHtml = useSelector((state: RootState) => state.draft.draftHtml);
  const dispatch = useDispatch();

  const encryptAndUpdateDraftTitle = useCallback(async () => {
    if (!decryptionKey) {
      return;
    }
    const encryptedTitle = encryptSymmetricString({
      secretKey: decryptionKey,
      decryptedPayload: draftTitle,
    });
    await updateEncryptedDraftTitle({
      variables: {
        draftID,
        encryptedTitle,
        oldTitleChecksum: titleChecksum,
      },
    });
  }, [
    decryptionKey,
    draftID,
    draftTitle,
    titleChecksum,
    updateEncryptedDraftTitle,
  ]);

  useEffect(() => {
    if (savedDraftTitle && savedDraftTitle !== draftTitle) {
      setDraftTitle(savedDraftTitle);
    }
  }, [draftTitle, savedDraftTitle]);

  const debouncedEncryptAndUpdateDraftTitle = useDebouncedCallback(
    encryptAndUpdateDraftTitle,
    300
  );

  const [inputWidth, setInputWidth] = useState(0);
  const hiddenSpanRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    const updateInputWidth = () => {
      if (hiddenSpanRef.current) {
        const width = roomName
          ? hiddenSpanRef.current.getBoundingClientRect().width
          : 90;
        const screenWidth = window.innerWidth;
        let bufferNeeded = isMobile ? 350 : 600;
        if (isPresetRoom) {
          bufferNeeded -= 123;
        }
        setInputWidth(Math.min(width, screenWidth - bufferNeeded));
      }
    };

    // Initial update
    updateInputWidth();

    // Add resize event listener
    window.addEventListener("resize", updateInputWidth);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateInputWidth);
    };
  }, [roomName, isMobile, isPresetRoom]);

  const isRoomOwner = useMemo(() => {
    return userID === roomData?.getRoomData?.ownerID;
  }, [userID, roomData?.getRoomData?.ownerID]);

  const isPersonalRoom = useMemo(() => {
    return roomData?.getRoomData?.presetID === RoomPresets.PersonalRoom;
  }, [roomData?.getRoomData?.presetID]);

  const showDeleteRoomButton = useMemo(() => {
    return isRoomOwner && !isPersonalRoom;
  }, [isRoomOwner, isPersonalRoom]);

  return (
    <>
      <DraftSelectModal
        open={allDraftsModalOpen}
        setOpen={setAllDraftsModalOpen}
        dismissable={true}
        roomID={roomID}
      />
      <div
        className="w-full flex flex-row py-2 items-center border-b border-solid border-black px-3 fixed"
        style={{ backgroundColor: "white", zIndex: 1000 }}
      >
        <Dropdown
          className="font-sans"
          closeOnSelect={false}
          isOpen={dropdownOpen}
          onOpenChange={async (isOpen) => {
            if (isOpen) {
              const result = await refetchRoomData();
              setPromptHidden(result.data.getRoomData.promptHidden);
            }
            setDropdownOpen(isOpen);
          }}
        >
          <DropdownTrigger
            className="cursor-pointer"
            onClick={() => {
              setDropdownOpen(!dropdownOpen);
            }}
          >
            <img
              src={Logo}
              alt="Logo"
              style={{
                width: "40px",
                height: "40px",
                objectFit: "cover",
                objectPosition: "center",
                borderRadius: 10,
              }}
            />
          </DropdownTrigger>
          <DropdownMenu
            aria-label="Special actions"
            disabledKeys={["no-drafts"]}
            onAction={async (key) => {
              let closeDropdown = true;
              if (key === IconDropdownKeys.Home) {
                navigate("/");
                return;
              } else if (key === IconDropdownKeys.CreateNew) {
                const response: { data?: { createDraft: string } } =
                  await createDraft();
                if (!response.data) {
                  console.error("Failed to create draft");
                  return;
                }
                navigate(
                  getDraftPath({ draftID: response.data.createDraft, roomID })
                );
                return;
              } else if (key === IconDropdownKeys.SelectDraft) {
                setAllDraftsModalOpen(true);
              } else if (key === IconDropdownKeys.DeleteRoom) {
                if (
                  window.confirm("Are you sure you want to delete this room?")
                ) {
                  await deleteRoom({ variables: { roomID } });
                  navigate("/");
                }
              } else if (key === IconDropdownKeys.ExportToDocx) {
                if (!draftHtml) {
                  return;
                }
                setExportingToWord(true);
                try {
                  const buffer = await asBlob(draftHtml);
                  fileDownload(
                    buffer,
                    `${draftTitle ? draftTitle : "Untitled"}.docx`
                  );
                  closeDropdown = true;
                } catch (err) {
                  console.error("Error exporting to Word", { err });
                } finally {
                  setExportingToWord(false);
                }
              } else if (key === IconDropdownKeys.ExportToMarkdown) {
                if (!draftHtml) {
                  return;
                }
                try {
                  const turndownService = new TurndownService();
                  const markdown = turndownService.turndown(draftHtml);
                  fileDownload(
                    markdown,
                    `${draftTitle ? draftTitle : "Untitled"}.md`
                  );
                  closeDropdown = true;
                } catch (err) {
                  console.error("Error exporting to Markdown", { err });
                } finally {
                  setExportingToMarkdown(false);
                }
              } else if (key === IconDropdownKeys.GeneratePrompt) {
                await updateRoomPromptHidden({
                  variables: { roomID, promptHidden: !promptHidden },
                });
                setPromptHidden(!promptHidden);
                navigate(0);
              } else {
                navigate(getDraftPath({ draftID: key as string, roomID }));
              }
              if (closeDropdown) {
                setDropdownOpen(false);
              }
            }}
          >
            <DropdownSection aria-label="Special actions" showDivider>
              {[
                <DropdownItem
                  key={IconDropdownKeys.Home}
                  startContent={<Home3 size={16} />}
                >
                  Home
                </DropdownItem>,
                <DropdownItem
                  key={IconDropdownKeys.CreateNew}
                  startContent={<Document size={16} />}
                >
                  New draft
                </DropdownItem>,

                ...(isMobile
                  ? []
                  : [
                      <DropdownItem
                        key={IconDropdownKeys.ExportToDocx}
                        startContent={
                          exportingToWord ? (
                            <Spinner
                              color="default"
                              classNames={{ wrapper: "w-4 h-4" }}
                            />
                          ) : (
                            <DocumentDownload size={16} />
                          )
                        }
                      >
                        Export to Word
                      </DropdownItem>,

                      <DropdownItem
                        key={IconDropdownKeys.ExportToMarkdown}
                        startContent={
                          exportingToMarkdown ? (
                            <Spinner
                              color="default"
                              classNames={{ wrapper: "w-4 h-4" }}
                            />
                          ) : (
                            <DocumentDownload size={16} />
                          )
                        }
                      >
                        Export to Google Docs
                      </DropdownItem>,
                    ]),
                <DropdownItem
                  startContent={
                    promptHidden ? <Eye size={16} /> : <EyeSlash size={16} />
                  }
                  key={IconDropdownKeys.GeneratePrompt}
                >
                  {promptHidden ? "Show prompt" : "Hide prompt"}
                </DropdownItem>,
              ]}
            </DropdownSection>

            <DropdownSection
              title="Recent drafts"
              showDivider={
                data?.getDraftsMetadata?.hasMore || showDeleteRoomButton
              }
            >
              {loading ? (
                <DropdownItem key="loading-drafts">
                  <div className="flex flex-col items-center">
                    <p className="font-sans mb-2">Loading your drafts...</p>
                    <Spinner />
                  </div>
                </DropdownItem>
              ) : draftMetadataList.length > 0 ? (
                draftMetadataList.map((metadata) => {
                  return (
                    <DropdownItem
                      key={metadata.draftID}
                      className="draft-title-item"
                      title={metadata.title ?? "Untitled"}
                      style={{ maxWidth: 200 }}
                    >
                      {metadata.title ?? "Untitled"}
                    </DropdownItem>
                  );
                })
              ) : (
                <DropdownItem key="no-drafts">No other drafts</DropdownItem>
              )}
            </DropdownSection>
            {data?.getDraftsMetadata?.hasMore && (
              <DropdownSection
                aria-label="Room actions"
                showDivider={showDeleteRoomButton}
              >
                <DropdownItem key={IconDropdownKeys.SelectDraft}>
                  View all drafts
                </DropdownItem>
              </DropdownSection>
            )}
            {showDeleteRoomButton ? (
              <DropdownSection aria-label="Room actions">
                <DropdownItem
                  key={IconDropdownKeys.DeleteRoom}
                  startContent={<Trash size={16} />}
                  className="text-danger"
                >
                  Delete room
                </DropdownItem>
              </DropdownSection>
            ) : null}
          </DropdownMenu>
        </Dropdown>
        <div className="flex-1" style={{ paddingLeft: 12 }}>
          <div className="flex flex-row items-center">
            <div className="flex-1">
              <Input
                placeholder="Document Title"
                classNames={{
                  input: ["font-sans font-semibold text-lg"],
                  inputWrapper: [
                    "bg-transparent",
                    "shadow-none",
                    "data-[hover=true]:bg-transparent",
                    "group-data-[focus=true]:bg-transparent",
                    "h-[48px]",
                    "py-[5px]",
                    "w-full",
                    "px-0",
                    "min-h-unit-0",
                  ],
                  innerWrapper: ["pb-0"],
                }}
                value={draftTitle}
                onValueChange={(value) => {
                  setDraftTitle(value);
                  dispatch(reduxSetDraftTitle({ title: value }));
                  debouncedEncryptAndUpdateDraftTitle();
                }}
              />
            </div>
          </div>
        </div>
        {!isMobile && (
          <div style={{ marginRight: 20 }}>
            <FeedbackButton />
          </div>
        )}
        <ProfileMenu />
      </div>
      <div style={{ minHeight: 57 }}></div>
    </>
  );
}

export default DocumentEditorHeader;
