import ListItem from "@tiptap/extension-list-item";
import TextStyle from "@tiptap/extension-text-style";
import { EditorProvider } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import CharacterCount from "@tiptap/extension-character-count";
import { useEffect, useState, useCallback } from "react";
import { LiteralTab } from "../utils/tabExtension";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useUserInfo } from "../hooks/useUserInfo";
import { encryptSymmetricString } from "../crypto/utils";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { useDebouncedCallback } from "use-debounce";
import {
  setDraftHtml,
  setDraftChecksum,
  setOutOfSync,
} from "../redux/draftStore";
import { UserRoomStatus, RoomMetadata } from "../gql/graphql";
import Placeholder from "@tiptap/extension-placeholder";
import { Socket } from "socket.io-client";
import { setUnauthedDraftData } from "../utils/unauthedLocalStorage";
import { Button } from "@nextui-org/react";
import EarthBackground from "../assets/earth-background.png";
import { useInterval } from "../hooks/useInterval";
import { useMediaQuery } from "react-responsive";

const extensions = [
  // @ts-ignore
  TextStyle.configure({ type: [ListItem.name] }),
  StarterKit.configure({
    bulletList: {
      keepMarks: true,
      keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
    },
    codeBlock: false,
    code: false,
  }),
  CharacterCount.configure(),
  LiteralTab.configure(),
  Placeholder.configure({
    placeholder: "Your draft: also completely private and only visible to you",
  }),
];

const UPDATE_DRAFT_HTML_MUTATION = gql`
  mutation updateDraftHtml(
    $draftID: String!
    $encryptedDraftHtml: String!
    $oldDraftChecksum: String
    $wordsAdded: Int!
    $wordsDeleted: Int!
    $timezone: String!
    $roomID: String!
  ) {
    updateDraftHtml(
      request: {
        draftID: $draftID
        encryptedDraftHtml: $encryptedDraftHtml
        oldDraftChecksum: $oldDraftChecksum
        wordsAdded: $wordsAdded
        wordsDeleted: $wordsDeleted
        timezone: $timezone
        roomID: $roomID
      }
    ) {
      newDraftChecksum
    }
  }
`;

const INCREMENT_WORDS_WRITTEN_MUTATION = gql`
  mutation incrementWordsWritten($roomID: String!, $wordsWritten: Int!) {
    incrementWordsWritten(
      request: { roomID: $roomID, wordsWritten: $wordsWritten }
    ) {
      success
    }
  }
`;

const GET_ROOM_LIST = gql`
  query GetRoomListDocumentEditor {
    getRoomList {
      sharedRooms {
        roomID
        name
        timeLeftSeconds
        endTime
        imageDownloadURL
        private
        permanent
        ownerID
        numUsersInRoom
        friendsInRoom {
          username
          userID
          profilePhotoURL
        }
        roomStatus
      }
      ownedRooms {
        roomID
        name
        timeLeftSeconds
        endTime
        imageDownloadURL
        private
        permanent
        ownerID
        numUsersInRoom
        friendsInRoom {
          username
          userID
          profilePhotoURL
        }
        roomStatus
      }
    }
  }
`;

const DECLINE_ROOM_INVITE = gql`
  mutation DeclineRoomInvite($roomID: String!) {
    declineRoomInvite(request: { roomID: $roomID }) {
      success
    }
  }
`;

export default function MainDocumentEditor(props: {
  socket: Socket | null;
  setLiveWordCount: (wordCount: number) => void;
  maxHeight?: number;
}) {
  const isMobile = useMediaQuery({
    query: "(max-width: 680px)",
  });
  const { socket, setLiveWordCount, maxHeight } = props;
  const dispatch = useDispatch();
  const { roomID } = useParams();
  const [updateDraftHtml] = useMutation(UPDATE_DRAFT_HTML_MUTATION, {
    onCompleted: (data) => {
      if (data.updateDraftHtml) {
        dispatch(setDraftChecksum(data.updateDraftHtml.newDraftChecksum));
      }
    },
    onError: (e) => {
      const errorCodes = e.graphQLErrors.map((error) => error.extensions?.code);
      if (errorCodes.includes("CHECKSUM_MISMATCH")) {
        dispatch(setOutOfSync());
      }
    },
  });
  const [incrementWordsWritten] = useMutation(INCREMENT_WORDS_WRITTEN_MUTATION);
  const draftHtml = useSelector((state: RootState) => state.draft.draftHtml);
  const draftChecksum = useSelector(
    (state: RootState) => state.draft.draftChecksum
  );
  const [initialLoadCompleted, setInitialLoadCompleted] = useState(false);
  const [requestPending, setRequestPending] = useState(false);
  const [draftDirty, setDraftDirty] = useState(false);
  const loaded = useSelector((state: RootState) => state.draft.loaded);
  const [localHtml, setLocalHtml] = useState(draftHtml);
  const { decryptionKey, isLoggedIn } = useUserInfo();
  const { draftID } = useParams();

  // word count from the last time data was updated on client
  const [lastSubmittedWordCount, setLastSubmittedWordCount] = useState<
    number | null
  >(null);

  const [draftWordsAdded, setDraftWordsAdded] = useState<number>(0);
  const [draftWordsDeleted, setDraftWordsDeleted] = useState<number>(0);

  const getCurrentTextWordCount = useCallback((editor: any) => {
    const text = editor.getText();
    return text.match(/\S+/g)?.length || 0;
  }, []);

  const setCurrentTextWordCount = useCallback(
    (editor: any) => {
      const newWordCount = getCurrentTextWordCount(editor);
      setLastSubmittedWordCount(newWordCount);
    },
    [getCurrentTextWordCount]
  );

  const saveEncryptedDraftHtml = useCallback(async () => {
    if (decryptionKey && localHtml && draftID) {
      const encryptedDraftHtml = encryptSymmetricString({
        secretKey: decryptionKey,
        decryptedPayload: localHtml,
      });
      const curDraftWordsAdded = draftWordsAdded;
      const curDraftWordsDeleted = draftWordsDeleted;
      setDraftWordsAdded(0);
      setDraftWordsDeleted(0);
      await updateDraftHtml({
        variables: {
          draftID,
          encryptedDraftHtml,
          oldDraftChecksum: draftChecksum,
          wordsAdded: curDraftWordsAdded,
          wordsDeleted: curDraftWordsDeleted,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          roomID,
        },
      });
      setRequestPending(false);
    }
  }, [
    decryptionKey,
    draftChecksum,
    draftID,
    draftWordsAdded,
    draftWordsDeleted,
    localHtml,
    roomID,
    updateDraftHtml,
  ]);

  // set to 100 to guard against useeffect retriggering
  const shorterDebouncedSaveEncryptedDraftHtml = useDebouncedCallback(
    saveEncryptedDraftHtml,
    100
  );

  useEffect(() => {
    if (draftDirty && !requestPending && isLoggedIn) {
      setRequestPending(true);
      setDraftDirty(false);
      shorterDebouncedSaveEncryptedDraftHtml();
    }
  }, [
    draftDirty,
    requestPending,
    shorterDebouncedSaveEncryptedDraftHtml,
    isLoggedIn,
  ]);

  const debouncedSaveEncryptedDraftHtml = useDebouncedCallback(
    saveEncryptedDraftHtml,
    500
  );

  const onUpdateCallback = useCallback(
    async (args: { editor: any; transaction: any }) => {
      // if we haven't loaded from server yet, don't update.
      if (!initialLoadCompleted) {
        return;
      }
      const { editor, transaction } = args;
      // This is very TipTap specific and not great, for some reason the update is firing even for mouse clicks
      // Alternatively we could compare curr/prev state but this might be faster computationally
      // since TipTap is already tracking things
      // 'replacearound' corresponds to a cursor click action (no actual addition/deletion)
      const charsChanged =
        transaction.docChanged &&
        transaction.steps.some((s: any) => s.jsonID !== "replaceAround");

      // No need to do anything if content has not changed
      if (!charsChanged) {
        return;
      }

      // Saves changes server-side
      const html = editor.getHTML();
      setLocalHtml(html);
      dispatch(setDraftHtml({ draftHtml: html }));

      if (lastSubmittedWordCount === null) {
        console.warn("No word count for some reason");
        return;
      }

      const newWordCount = getCurrentTextWordCount(editor);
      setLiveWordCount(newWordCount);
      const wordsAdded = Math.max(newWordCount - lastSubmittedWordCount, 0);
      const wordsDeleted = Math.max(lastSubmittedWordCount - newWordCount, 0);
      // Update the last word count regardless
      setLastSubmittedWordCount(newWordCount);

      setDraftWordsAdded(draftWordsAdded + wordsAdded);
      setDraftWordsDeleted(draftWordsDeleted + wordsDeleted);
      if (!requestPending && !draftDirty && isLoggedIn) {
        setRequestPending(true);
        debouncedSaveEncryptedDraftHtml();
      } else if (requestPending && !draftDirty && isLoggedIn) {
        setDraftDirty(true);
      }

      // We don't need to do any additional updates
      if (lastSubmittedWordCount === newWordCount) {
        return;
      }

      if (!transaction.meta.paste) {
        if (socket && roomID) {
          if (wordsAdded > 0) {
            socket.emit("awareness_activity", {
              type: "word_added",
              roomID,
              payload: { wordsAdded },
            });
          }
          if (wordsDeleted > 0) {
            socket.emit("awareness_activity", { type: "word_deleted", roomID });
          }
        }

        if (newWordCount > lastSubmittedWordCount) {
          await incrementWordsWritten({
            variables: {
              roomID,
              wordsWritten: newWordCount - lastSubmittedWordCount,
            },
          });
        }
      }
    },
    [
      initialLoadCompleted,
      dispatch,
      lastSubmittedWordCount,
      getCurrentTextWordCount,
      setLiveWordCount,
      socket,
      roomID,
      draftWordsAdded,
      draftWordsDeleted,
      requestPending,
      draftDirty,
      isLoggedIn,
      debouncedSaveEncryptedDraftHtml,
      incrementWordsWritten,
    ]
  );

  const debouncedOnUpdateCallback = useDebouncedCallback(onUpdateCallback, 100);

  useEffect(() => {
    if (loaded && !initialLoadCompleted) {
      setLocalHtml(draftHtml);
      setInitialLoadCompleted(true);
    }
  }, [draftHtml, initialLoadCompleted, loaded, localHtml]);

  useEffect(() => {
    if (!isLoggedIn) {
      setUnauthedDraftData(draftHtml);
    }
  }, [draftHtml, isLoggedIn]);

  const [invitedRooms, setInvitedRooms] = useState<RoomMetadata[]>([]);
  const navigate = useNavigate();
  const [declineRoomInvite] = useMutation(DECLINE_ROOM_INVITE);

  const handleAccept = useCallback(
    (roomID: string) => {
      setInvitedRooms(invitedRooms.filter((room) => room.roomID !== roomID));
      navigate(`/room/${roomID}/draft/${draftID}`);
    },
    [invitedRooms, navigate, draftID]
  );

  const handleDecline = useCallback(
    async (roomID: string) => {
      const filteredRooms = invitedRooms.filter(
        (room) => room.roomID !== roomID
      );
      setInvitedRooms(filteredRooms);
      await declineRoomInvite({ variables: { roomID } });
    },
    [declineRoomInvite, invitedRooms]
  );

  const handleRoomInvite = useCallback(
    (roomMetadata: RoomMetadata[]) => {
      const invitedRoomsData = roomMetadata.filter(
        (room: RoomMetadata) => room.roomStatus === UserRoomStatus.Invited
      );
      const updatedInvitedRooms: RoomMetadata[] = [];
      for (const room of invitedRoomsData) {
        const matchingExistingRoom = invitedRooms.find(
          (r) => r.roomID === room.roomID
        );
        if (matchingExistingRoom) {
          updatedInvitedRooms.push({
            ...room,
            imageDownloadURL: matchingExistingRoom.imageDownloadURL,
          });
        } else {
          updatedInvitedRooms.push(room);
        }
      }
      setInvitedRooms(updatedInvitedRooms);
    },
    [invitedRooms]
  );

  const { data, loading, refetch } = useQuery(GET_ROOM_LIST, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data.getRoomList) {
        const { sharedRooms: sharedRoomsData } = data.getRoomList;
        handleRoomInvite(sharedRoomsData);
      }
    },
  });

  useInterval(async () => {
    const result = await refetch();

    if (result.data.getRoomList) {
      const { sharedRooms: sharedRoomsData } = result.data.getRoomList;
      handleRoomInvite(sharedRoomsData);
    }
  }, 5000);

  if (!loaded) {
    return null;
  }

  return (
    <div className="flex flex-col h-full">
      <div
        className="h-full flex flex-col overflow-y-auto"
        id="document-editor-container" // this ID is used in index.css
        style={{
          flex: 1,
          padding: 40,
          maxHeight: maxHeight
            ? `calc(100dvh - ${maxHeight}px - 57px - 30px)`
            : undefined,
        }}
      >
        <EditorProvider
          slotBefore={null}
          slotAfter={
            <div
              style={{ minHeight: isMobile ? undefined : 500, height: 500 }}
            />
          }
          extensions={extensions}
          content={draftHtml}
          editorProps={{
            attributes: {
              className: "h-full",
              "data-testid": "input-editor-provider",
            },
            transformPastedHTML: (html: string) => {
              // any desired html transformations can go here
              return html;
            },
          }}
          onCreate={async ({ editor }) => {
            setLiveWordCount(getCurrentTextWordCount(editor));
            setCurrentTextWordCount(editor);
          }}
          onTransaction={({ transaction, editor }) => {
            setLiveWordCount(getCurrentTextWordCount(editor));
            debouncedOnUpdateCallback({ transaction, editor });
          }}
        >
          {null}
        </EditorProvider>
      </div>
      {invitedRooms.length > 0 && (
        <div
          style={{
            position: "absolute",
            bottom: 20,
            right: 20,
            backgroundColor: "white",
            borderRadius: 10,
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            padding: 16,
            minWidth: 300,
            maxHeight: 400,
            overflowY: "auto",
          }}
        >
          <h3
            className="font-sans text-lg font-bold mb-4"
            style={{ marginBottom: 20 }}
          >
            Room Invitations
          </h3>
          {invitedRooms.map((room) => (
            <div
              key={room.roomID}
              className="mb-4 pb-4"
              style={{ marginBottom: 10 }}
            >
              <div className="flex justify-between mb-2">
                <div className="flex items-center" style={{ marginRight: 30 }}>
                  <img
                    key={`${room.roomID}-invite-img-${room.imageDownloadURL}`}
                    src={room.imageDownloadURL || EarthBackground}
                    alt={room.name}
                    className="w-10 h-10 mr-3"
                    style={{
                      borderRadius: 6,
                      marginRight: 10,
                    }}
                  />
                  <p className="font-sans font-semibold">{room.name}</p>
                </div>
                <div className="flex justify-end items-center">
                  <Button
                    size="sm"
                    color="success"
                    className="font-sans mr-2"
                    style={{
                      color: "white",
                    }}
                    onClick={() => handleAccept(room.roomID)}
                  >
                    Accept
                  </Button>
                  <Button
                    size="sm"
                    color="danger"
                    className="font-sans"
                    onClick={() => handleDecline(room.roomID)}
                  >
                    Decline
                  </Button>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
