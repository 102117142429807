import { Avatar, Button, Input } from "@nextui-org/react";
import dayjs from "dayjs";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Socket } from "socket.io-client";
import { useInterval } from "../hooks/useInterval";
import { gql, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { ArrowLeft2, ArrowUp2 } from "iconsax-react";
import { Message } from "../gql/graphql";
import { useUserInfo } from "../hooks/useUserInfo";
import { getUnauthedUserHash } from "../utils/unauthedLocalStorage";
import { useMediaQuery } from "react-responsive";
import ProfileAvatar from "./ProfileAvatar";

export default function DocumentFooter({
  liveWordCount,
}: {
  liveWordCount: number;
}) {
  const isMobile = useMediaQuery({
    query: "(max-width: 680px)",
  });
  const [encryptedTooltipOpen, setEncryptedTooltipOpen] = useState(false);
  const [timeOfDayTooltipOpen, setTimeOfDayTooltipOpen] = useState(false);

  const currentHour = dayjs().hour();
  const timeOfDayString = useMemo(() => {
    if (currentHour < 1) {
      return "nightfall 🥱";
    } else if (currentHour < 4) {
      return "dead of night ☠️";
    } else if (currentHour < 7) {
      return "daybreak 🐓";
    } else if (currentHour < 11) {
      return "morning ☕";
    } else if (currentHour < 13) {
      return "midday 🕛";
    } else if (currentHour < 17) {
      return "afternoon ☀️";
    } else if (currentHour < 22) {
      return "evening 🌙";
    } else {
      return "nightfall 🥱";
    }
  }, [currentHour]);

  return (
    <div className="flex flex-col items-end">
      <div
        className={`flex items-end ${
          isMobile ? "justify-end" : "justify-between"
        } ${isMobile ? "flex-col-reverse" : "flex-row"}`}
        style={{
          backgroundColor: "white",
          cursor: "default",
          width: "100vw",
          borderTop: "1px solid #e2e8f0",
          height: isMobile ? undefined : 30,
        }}
      >
        <div
          className={`flex items-center`}
          style={{
            paddingRight: 8,
            paddingLeft: 8,
            height: 30,
            flexDirection: isMobile ? "row" : "row-reverse",
          }}
        >
          {timeOfDayTooltipOpen && (
            <div
              className="absolute"
              style={{
                bottom: 30,
                left: 0,
                backgroundColor: "black",
                padding: 4,
                width: 316,
                borderRadius: 8,
              }}
            >
              <p
                className="font-sans"
                style={{ fontSize: 12, color: "white", textAlign: "center" }}
              >
                The time of day based on your timezone. You can check your
                profile to see your writing habits over time.
              </p>
            </div>
          )}
          {encryptedTooltipOpen && (
            <div
              className="absolute"
              style={{
                bottom: 30,
                backgroundColor: "black",
                padding: 4,
                width: 316,
                left: 0,
                borderRadius: 8,
              }}
            >
              <p
                className="font-sans"
                style={{ fontSize: 12, color: "white", textAlign: "center" }}
              >
                Your document, notes, and title are completely private. No one
                besides you, not even the Draft Zero team, can read it.
              </p>
            </div>
          )}
          <p className="font-sans" style={{ fontSize: 12, color: "gray" }}>
            {liveWordCount} word{liveWordCount !== 1 ? "s" : ""}
          </p>
          <div style={{ marginLeft: 4, marginRight: 4 }}>
            <p>·</p>
          </div>
          <div
            onMouseEnter={() => {
              if (!isMobile) {
                setTimeOfDayTooltipOpen(true);
              }
            }}
            onMouseLeave={() => {
              if (!isMobile) {
                setTimeOfDayTooltipOpen(false);
              }
            }}
          >
            <p
              className="font-sans"
              style={{
                fontSize: 12,
                color: "gray",
                cursor: isMobile ? "default" : "help",
              }}
            >
              {timeOfDayString}
            </p>
          </div>
          <div style={{ marginLeft: 4, marginRight: 4 }}>
            <p>·</p>
          </div>
          <p
            className="font-sans"
            style={{
              fontSize: 12,
              color: "gray",
              cursor: isMobile ? "default" : "help",
            }}
            onMouseEnter={() => {
              if (!isMobile) {
                setEncryptedTooltipOpen(true);
              }
            }}
            onMouseLeave={() => {
              if (!isMobile) {
                setEncryptedTooltipOpen(false);
              }
            }}
          >
            encrypted
          </p>
        </div>
      </div>
    </div>
  );
}
