import { PanelResizeHandle } from "react-resizable-panels";

export default function ResizeHandle({
  className = "",
}: {
  className?: string;
}) {
  return (
    <PanelResizeHandle
      className={["resize-handler-border", className].join(" ")}
    >
    {/* <svg className="resize-icon" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0.8 0.18 0.29 1.52">
        <path fill="currentColor" fillRule="evenodd" clipRule="evenodd" d="M1.078 0.313a0.141 0.141 0 1 1 -0.281 0 0.141 0.141 0 0 1 0.281 0m0 0.625a0.141 0.141 0 1 1 -0.281 0 0.141 0.141 0 0 1 0.281 0M0.938 1.703a0.141 0.141 0 1 0 0 -0.281 0.141 0.141 0 0 0 0 0.281"></path>
    </svg> */}
    </PanelResizeHandle>
  );
}
