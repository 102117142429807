import { useEffect, useMemo, useState } from "react";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { DraftMetadata as gqlDraftMetadata } from "../gql/graphql";
import { useUserInfo } from "../hooks/useUserInfo";
import { decryptSymmetricString } from "../crypto/utils";
import { EditorProvider } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import CharacterCount from "@tiptap/extension-character-count";
import { LiteralTab } from "../utils/tabExtension";
import Placeholder from "@tiptap/extension-placeholder";
import ListItem from "@tiptap/extension-list-item";
import TextStyle from "@tiptap/extension-text-style";
import { Button, Spinner } from "@nextui-org/react";
import { ArrowRight } from "iconsax-react";
import { useNavigate } from "react-router-dom";
import PlaintextTitleHeader from "../components/PlaintextTitleHeader";

const GET_ALL_DRAFTS = gql`
  query GetAllDrafts {
    getAllDrafts {
      drafts {
        draftID
        encryptedTitle
        updatedAt
      }
    }
  }
`;

const GET_DRAFT = gql`
  query GetDraft($draftID: String!) {
    getDraft(request: { draftID: $draftID }) {
      draftID
      encryptedDraftHtml
    }
  }
`;

type DraftMetadata = {
  draftID: string;
  title: string | null;
  updatedAt: Date;
};

const extensions = [
  // @ts-ignore
  TextStyle.configure({ type: [ListItem.name] }),
  StarterKit.configure({
    bulletList: {
      keepMarks: true,
      keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
    },
    codeBlock: false,
    code: false,
  }),
  CharacterCount.configure(),
  LiteralTab.configure(),
  Placeholder.configure({
    placeholder: "Draft",
  }),
];

function ViewAllDrafts() {
  const { data } = useQuery(GET_ALL_DRAFTS, {
    fetchPolicy: "no-cache",
  });

  const [getDraft, { data: draftData, loading: draftLoading }] = useLazyQuery(
    GET_DRAFT,
    {
      fetchPolicy: "no-cache",
    }
  );

  const [hovered, setHovered] = useState<string | null>(null);

  const [selectedDraftID, setSelectedDraftID] = useState<string | null>(null);

  const navigate = useNavigate();

  const { decryptionKey } = useUserInfo();

  const draftMetadataList = useMemo<DraftMetadata[]>(() => {
    if (!data || !decryptionKey) {
      return [];
    }
    return data.getAllDrafts.drafts.map((metadata: gqlDraftMetadata) => {
      return {
        draftID: metadata.draftID,
        title: decryptSymmetricString({
          encryptedPayload: metadata.encryptedTitle,
          secretKey: decryptionKey,
        }),
        updatedAt: new Date(metadata.updatedAt),
      };
    });
  }, [data, decryptionKey]);

  const decryptedDraftData = useMemo(() => {
    if (!draftData || !decryptionKey) {
      return;
    }
    const decryptedDraftHtml = draftData.getDraft?.encryptedDraftHtml
      ? decryptSymmetricString({
          secretKey: decryptionKey,
          encryptedPayload: draftData.getDraft.encryptedDraftHtml,
        })
      : null;
    return {
      draftHtml: decryptedDraftHtml,
    };
  }, [decryptionKey, draftData]);

  useEffect(() => {
    if (selectedDraftID) {
      getDraft({ variables: { draftID: selectedDraftID } });
    }
  }, [getDraft, selectedDraftID]);

  return (
    <div className="h-full flex flex-col">
      <PlaintextTitleHeader title="All drafts" />
      <div className="flex flex-row h-full">
        <div
          style={{
            flex: 1,
            overflowY: "auto",
            borderRightWidth: 1,
            borderColor: "black",
            height: "calc(100dvh - 57px)",
          }}
        >
          {draftMetadataList &&
            draftMetadataList.map((draft) => {
              return (
                <div
                  key={draft.draftID}
                  style={{
                    padding: 10,
                    backgroundColor:
                      hovered === draft.draftID
                        ? "#f0f0f0"
                        : selectedDraftID === draft.draftID
                        ? "#7194A8"
                        : "white",
                    cursor: "pointer",
                  }}
                  onMouseEnter={() => {
                    setHovered(draft.draftID);
                  }}
                  onMouseLeave={() => {
                    setHovered(null);
                  }}
                  onClick={() => {
                    setSelectedDraftID(draft.draftID);
                  }}
                >
                  <p
                    className="font-sans"
                    style={{ fontSize: 16, fontWeight: "bold" }}
                  >
                    {draft.title ?? "Untitled"}
                  </p>
                  <p className="font-sans" style={{ fontSize: 10 }}>
                    {draft.updatedAt.toLocaleDateString("en-US", {
                      month: "long",
                      day: "numeric",
                      year: "numeric",
                    })}
                  </p>
                </div>
              );
            })}
        </div>
        <div style={{ flex: 3 }}>
          {draftLoading && <Spinner />}
          {!selectedDraftID && !draftLoading && (
            <div style={{ padding: 20 }}>
              <p className="font-sans">Select a draft to view</p>
            </div>
          )}

          {!draftLoading && selectedDraftID && (
            <div
              style={{
                padding: 20,
                height: "calc(100dvh - 57px)",
                display: "flex",
                flexDirection: "column",
                overflowY: "auto",
              }}
            >
              <div style={{ flex: 1 }}>
                {selectedDraftID &&
                  !decryptedDraftData?.draftHtml &&
                  !draftLoading && (
                    <div>
                      <p className="font-sans" style={{ color: "#d4d4d8" }}>
                        Empty
                      </p>
                    </div>
                  )}
                {decryptedDraftData?.draftHtml && (
                  <EditorProvider
                    slotBefore={null}
                    slotAfter={null}
                    extensions={extensions}
                    content={decryptedDraftData?.draftHtml}
                    editorProps={{
                      attributes: {
                        className: "h-full font-serif",
                      },
                      transformPastedHTML: (html: string) => {
                        // any desired html transformations can go here
                        return html;
                      },
                    }}
                    editable={false}
                  >
                    {null}
                  </EditorProvider>
                )}
              </div>

              <div
                className="flex flex-row justify-end"
                style={{ position: "absolute", bottom: 20, right: 30 }}
              >
                <Button
                  className="font-sans"
                  color="primary"
                  endContent={<ArrowRight />}
                  size="sm"
                  onClick={() => {
                    navigate(`/draft/${selectedDraftID}`);
                  }}
                >
                  ENTER
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ViewAllDrafts;
