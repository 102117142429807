import { useEffect, useState } from "react";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { ActivityLog } from "../gql/graphql";
import { ActivityLogFeedItem } from "./ActivityLogFeedItem";

const GET_PROFILE_ACTIVITY_FEED = gql`
  query GetProfileActivityFeed($username: String!) {
    getActivityLogFeed(request: { username: $username }) {
      activityLogID
      templatedLog
      createdAt
    }
  }
`;

const GET_MOST_RECENTLY_EDITED_DRAFT = gql`
  query GetMostRecentlyEditedDraft {
    getMostRecentlyEditedDraft {
      draftID
    }
  }
`;

function ProfileActivityFeed() {
  const { username } = useParams();

  const [profileActivityFeedData, setProfileActivityFeedData] = useState<
    ActivityLog[]
  >([]);
  const [getProfileActivityFeed, { data, loading, refetch }] = useLazyQuery(
    GET_PROFILE_ACTIVITY_FEED,
    {
      variables: { username },
      fetchPolicy: "no-cache",
    }
  );

  useEffect(() => {
    if (username) {
      getProfileActivityFeed({ variables: { username } }).then((data) => {
        if (data.data?.getActivityLogFeed) {
          setProfileActivityFeedData(data.data.getActivityLogFeed);
        }
      });
    }
  }, [username, getProfileActivityFeed]);

  const { data: mostRecentlyEditedDraftData } = useQuery(
    GET_MOST_RECENTLY_EDITED_DRAFT,
    {
      fetchPolicy: "no-cache",
    }
  );

  return (
    <div
      className="flex flex-col relative"
      style={{
        width: "100%",
        alignItems: "flex-start",
      }}
    >
      {profileActivityFeedData.map((activityLog: ActivityLog) => (
        <ActivityLogFeedItem
          key={activityLog.activityLogID}
          activityLog={activityLog}
          draftID={
            mostRecentlyEditedDraftData?.getMostRecentlyEditedDraft?.draftID
          }
        />
      ))}
    </div>
  );
}

export default ProfileActivityFeed;
