import { Avatar } from "@nextui-org/react";

export default function ProfileAvatar({
  src,
  style,
  profilePlaceholder,
  className,
  ...props
}: {
  src?: string;
  style?: React.CSSProperties;
  profilePlaceholder?: string;
  className?: string;
  [key: string]: any;
}) {
  if (src || !profilePlaceholder) {
    return (
      <Avatar
        src={src}
        showFallback
        style={style}
        className={className}
        {...props}
      />
    );
  }
  const color = profilePlaceholder.split("|")[0];
  const angle = profilePlaceholder.split("|")[1];
  return (
    <div style={{ position: "relative" }}>
      <Avatar
        className={className}
        style={{ ...style, position: "absolute" }}
        {...props}
      />
      <div
        className={className}
        style={{
          ...style,
          background: `linear-gradient(${angle}deg, #FFF 0%, #${color} 100%)`,
          borderRadius: "50%",
          zIndex: 2,
          position: "relative",
        }}
        {...props}
      />
    </div>
  );
}
