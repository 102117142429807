import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { Button, Input } from "@nextui-org/react";
import { useCallback, useState } from "react";
import {
  BeginRegistrationResponse,
  FinishLoginRequest,
  FinishResetPassword,
  RecoverAccountQuery,
  RecoverAccountResponse,
  StartLoginRequest,
  StartLoginResponse,
  StartResetPasswordRequest,
} from "../gql/graphql";
import {
  base64DecodeString,
  decryptSymmetric,
  deriveKeyFromMnemonic,
  encryptSymmetric,
  fromByteArrayB64,
  generateMnemonic,
  hashString,
  fromByteArray,
  toByteArray,
  clearLocalStorage,
} from "../crypto/utils";
import { client as opaqueClient } from "@serenity-kit/opaque";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../assets/logo.png";
import * as opaque from "@serenity-kit/opaque";

const RECOVER_ACCOUNT_QUERY = gql`
  query RecoverAccountQuery($email: String!) {
    recoverAccount(request: { email: $email }) {
      userID
      encryptedExportKey
      encryptedDecryptionKey
    }
  }
`;

const START_RESET_PASSWORD_QUERY = gql`
  query StartResetPassword($userID: String!, $registrationRequest: String!) {
    startResetPassword(
      request: { userID: $userID, registrationRequest: $registrationRequest }
    ) {
      registrationResponse
    }
  }
`;

const FINISH_RESET_PASSWORD_MUTATION = gql`
  mutation FinishResetPassword(
    $previousHashedExportKey: String!
    $encryptedPrivateKey: String!
    $encryptedExportKey: String!
    $hashedExportKey: String!
    $decryptedExportKey: String
    $registrationRecord: String!
    $userID: String!
  ) {
    finishResetPassword(
      request: {
        previousHashedExportKey: $previousHashedExportKey
        encryptedPrivateKey: $encryptedPrivateKey
        encryptedExportKey: $encryptedExportKey
        hashedExportKey: $hashedExportKey
        decryptedExportKey: $decryptedExportKey
        registrationRecord: $registrationRecord
        userID: $userID
      }
    )
  }
`;

const START_LOGIN_QUERY = gql`
  query StartLogin($email: String!, $startLoginRequest: String!) {
    startLogin(
      request: { email: $email, startLoginRequest: $startLoginRequest }
    ) {
      loginResponse
      userID
    }
  }
`;

const FINISH_LOGIN_QUERY = gql`
  query FinishLogin($finishLoginRequest: String!, $userID: String!) {
    finishLogin(
      request: { finishLoginRequest: $finishLoginRequest, userID: $userID }
    ) {
      success
    }
  }
`;

enum RecoverAccountState {
  RecoveryCode,
  UpdatePassword,
  ShowNewRecoveryCode,
}

function RecoverAccount() {
  const [recoverAccountQuery] = useLazyQuery(RECOVER_ACCOUNT_QUERY, {
    fetchPolicy: "no-cache",
  });
  const [startResetPasswordQuery] = useLazyQuery(START_RESET_PASSWORD_QUERY, {
    fetchPolicy: "no-cache",
  });
  const [finishResetPasswordMutation] = useMutation(
    FINISH_RESET_PASSWORD_MUTATION,
    {
      fetchPolicy: "no-cache",
    }
  );
  const [startLoginQuery] = useLazyQuery(START_LOGIN_QUERY, {
    fetchPolicy: "no-cache",
  });
  const [finishLoginQuery] = useLazyQuery(FINISH_LOGIN_QUERY, {
    fetchPolicy: "no-cache",
  });
  const [recoverAccountState, setRecoverAccountState] =
    useState<RecoverAccountState>(RecoverAccountState.RecoveryCode);
  const [newRecoveryKey, setNewRecoveryKey] = useState("");
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [recoveryCode, setRecoveryCode] = useState("");
  const [exportKey, setExportKey] = useState<string | null>(null);
  const [decryptionKeyBytes, setDecryptionKeyBytes] =
    useState<Uint8Array | null>(null);
  const [userID, setUserID] = useState<string | null>(null);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [recoveryKeyCopied, setRecoveryKeyCopied] = useState(false);
  const recoverExportKey = useCallback(async () => {
    const recoverAccountVariables: RecoverAccountQuery = {
      email,
    };
    const res: { data: { recoverAccount: RecoverAccountResponse } } =
      await recoverAccountQuery({
        variables: recoverAccountVariables,
      });
    const key = deriveKeyFromMnemonic({ mnemonic: recoveryCode });
    const encryptedExportKey = res.data.recoverAccount.encryptedExportKey;
    const decryptedExportKey = decryptSymmetric({
      secretKey: fromByteArrayB64(key),
      encryptedPayload: encryptedExportKey,
    });
    const exportKey = fromByteArray(decryptedExportKey);
    const decryptionKey = decryptSymmetric({
      secretKey: exportKey,
      encryptedPayload: res.data.recoverAccount.encryptedDecryptionKey,
    });
    setExportKey(exportKey);
    setUserID(res.data.recoverAccount.userID);
    setDecryptionKeyBytes(decryptionKey);
    setRecoverAccountState(RecoverAccountState.UpdatePassword);
  }, [email, recoverAccountQuery, recoveryCode]);

  const updatePassword = useCallback(async () => {
    const previousHashedExportKey = hashString(exportKey!);
    const { clientRegistrationState, registrationRequest } =
      opaqueClient.startRegistration({ password: newPassword });

    const startResetPasswordVariables: StartResetPasswordRequest = {
      userID: userID!,
      registrationRequest,
    };
    const startResetPasswordResponse: {
      data: { startResetPassword: BeginRegistrationResponse };
    } = await startResetPasswordQuery({
      variables: startResetPasswordVariables,
    });
    const opaqueFinishRegistration = opaqueClient.finishRegistration({
      clientRegistrationState,
      registrationResponse:
        startResetPasswordResponse.data.startResetPassword.registrationResponse,
      password: newPassword,
    });
    const newExportKey = opaqueFinishRegistration.exportKey;
    const hashedExportKey = hashString(newExportKey);
    const mnemonic = generateMnemonic();
    setNewRecoveryKey(mnemonic);

    const mnemonicKey = deriveKeyFromMnemonic({ mnemonic });
    const encryptedExportKey = encryptSymmetric({
      secretKey: fromByteArrayB64(mnemonicKey),
      decryptedPayload: toByteArray(newExportKey),
    });
    const encryptedDecryptionKey = encryptSymmetric({
      secretKey: newExportKey,
      decryptedPayload: decryptionKeyBytes!,
    });
    const finishResetPasswordVariables: FinishResetPassword = {
      previousHashedExportKey,
      encryptedPrivateKey: encryptedDecryptionKey,
      encryptedExportKey,
      hashedExportKey,
      registrationRecord: opaqueFinishRegistration.registrationRecord,
      userID: userID!,
    };
    await finishResetPasswordMutation({
      variables: finishResetPasswordVariables,
    });

    setExportKey(newExportKey);
    localStorage.setItem("exportKey", newExportKey);
    const { clientLoginState, startLoginRequest } = opaque.client.startLogin({
      password: newPassword,
    });
    const startLoginVariables: StartLoginRequest = {
      email: email,
      startLoginRequest: startLoginRequest,
    };
    const result: { data: { startLogin: StartLoginResponse } } =
      await startLoginQuery({
        variables: startLoginVariables,
      });
    const loginResult = opaque.client.finishLogin({
      clientLoginState,
      loginResponse: result.data.startLogin.loginResponse,
      password: newPassword,
    });

    if (!loginResult) {
      clearLocalStorage();
      throw new Error("Login failed");
    }

    const finishLoginVariables: FinishLoginRequest = {
      finishLoginRequest: loginResult.finishLoginRequest,
      userID: result.data.startLogin.userID,
    };
    await finishLoginQuery({
      variables: finishLoginVariables,
    });

    setRecoverAccountState(RecoverAccountState.ShowNewRecoveryCode);
  }, [
    decryptionKeyBytes,
    email,
    exportKey,
    finishLoginQuery,
    finishResetPasswordMutation,
    newPassword,
    startLoginQuery,
    startResetPasswordQuery,
    userID,
  ]);
  //guard viable help tool venue mechanic much olympic spider pulse hamster tower blood token lyrics tool loud chat ignore enter submit border student sketch
  return (
    <div className="h-full flex flex-col items-center justify-center">
      <Link to="/">
        <div
          className="absolute top-0 left-0 flex flex-row items-center cursor-pointer"
          style={{ marginLeft: 12, marginTop: 8 }}
        >
          <img src={Logo} alt="logo" width={40} />
          <p
            className="ml-2 font-semibold"
            style={{ fontFamily: "Nothing You Could Do", fontSize: 20 }}
          >
            draft zero
          </p>
        </div>
      </Link>
      <div style={{ width: 300 }}>
        <p className="font-sans">
          {recoverAccountState === RecoverAccountState.ShowNewRecoveryCode
            ? "New recovery code"
            : "Recover Account"}
        </p>
        {recoverAccountState === RecoverAccountState.RecoveryCode && (
          <>
            <div style={{ marginBottom: 10 }}>
              <Input
                className="font-sans"
                label="Email"
                value={email}
                onValueChange={setEmail}
              />
            </div>
            <div style={{ marginBottom: 10 }}>
              <Input
                className="font-sans"
                label="Recovery Code"
                value={recoveryCode}
                onValueChange={setRecoveryCode}
              />
            </div>
            <div className="flex flex-row justify-end">
              <Button
                onClick={recoverExportKey}
                color="primary"
                className="font-sans"
              >
                Recover Account
              </Button>
            </div>
          </>
        )}
        {recoverAccountState === RecoverAccountState.UpdatePassword && (
          <>
            <div style={{ marginBottom: 10 }}>
              <Input
                className="font-sans"
                label="new password"
                value={newPassword}
                onValueChange={setNewPassword}
              />
            </div>
            <div style={{ marginBottom: 10 }}>
              <Input
                className="font-sans"
                label="confirm password"
                value={confirmPassword}
                onValueChange={setConfirmPassword}
              />
            </div>
            <div className="flex flex-row justify-end font-sans">
              <Button onClick={updatePassword} color="primary">
                Update Password
              </Button>
            </div>
          </>
        )}
        {recoverAccountState === RecoverAccountState.ShowNewRecoveryCode && (
          <>
            <div style={{ marginBottom: 10, marginTop: 10 }}>
              <p className="font-sans">
                Save this key somewhere safe. If you forget your password, you
                will not be able to recover your account without it. This is the
                only time you will see this key.
              </p>
            </div>
            <div
              style={{
                marginBottom: 10,
                backgroundColor: "#d4d4d8",
                borderRadius: 10,
                padding: 10,
              }}
            >
              <p className="font-sans">{newRecoveryKey}</p>
            </div>
            <div className="flex flex-row justify-end">
              <div className="relative">
                <Button
                  color="primary"
                  onClick={() => {
                    if (recoveryKeyCopied) {
                      navigate("/");
                      return;
                    }
                    navigator.clipboard.writeText(newRecoveryKey);
                    setRecoveryKeyCopied(true);
                  }}
                  className="font-sans"
                >
                  {recoveryKeyCopied ? "Continue" : "Copy recovery key"}
                </Button>
                {recoveryKeyCopied && (
                  <div
                    className="absolute"
                    style={{
                      backgroundColor: "black",
                      top: -50,
                      right: 7.5,
                      borderRadius: 10,
                    }}
                  >
                    <p
                      className="font-sans"
                      style={{ color: "white", padding: 10 }}
                    >
                      Copied!
                    </p>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default RecoverAccount;
