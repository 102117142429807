import { createSlice } from "@reduxjs/toolkit";
import { ActivityStatus } from "../gql/graphql";

export interface ActivityState {
  status: ActivityStatus | null;
  secondsElapsed: number;
  wordsAdded: number;
  wordsDeleted: number;
  loaded: boolean;
  activityID?: string;
  secondsElapsedArray?: number[];
  wordDifferentialArray?: number[];
  title?: string;
  description?: string;
  checksum: string;
  outOfSync: boolean;
}

const initialActivityState: ActivityState = {
  status: null,
  secondsElapsed: 0,
  wordsAdded: 0,
  wordsDeleted: 0,
  loaded: false,
  checksum: "",
  outOfSync: false,
};

export const activitySlice = createSlice({
  name: "activity",
  initialState: initialActivityState,
  reducers: {
    initializeActivity: (state, action) => {
      state.activityID = action.payload.activityID;
      state.checksum = action.payload.checksum;
      state.status = ActivityStatus.Active;
    },
    startRecording: (state) => {
      state.status = ActivityStatus.Active;
    },
    pauseRecording: (state) => {
      state.status = ActivityStatus.Paused;
    },
    finishRecording: (state) => {
      state.status = ActivityStatus.Unpublished;
    },
    resetRecording: (state) => {
      state.status = null;
      state.secondsElapsed = 0;
      state.wordsAdded = 0;
      state.wordsDeleted = 0;
      state.activityID = undefined;
      state.title = undefined;
      state.description = undefined;
      state.secondsElapsedArray = undefined;
      state.wordDifferentialArray = undefined;
      state.checksum = "";
    },
    togglePauseRecording: (state) => {
      state.status =
        state.status === ActivityStatus.Active
          ? ActivityStatus.Paused
          : ActivityStatus.Active;
    },
    incrementWordsAdded: (state, action) => {
      state.wordsAdded = state.wordsAdded + action.payload;
    },
    incrementWordsDeleted: (state, action) => {
      state.wordsDeleted = state.wordsDeleted + action.payload;
    },
    incrementSecondsElapsed: (state) => {
      state.secondsElapsed = state.secondsElapsed + 1;
    },
    setLoaded: (state) => {
      state.loaded = true;
    },
    setUnloaded: (state) => {
      state.loaded = false;
    },
    setChartData: (state, action) => {
      state.secondsElapsedArray = action.payload.secondsElapsedArray;
      state.wordDifferentialArray = action.payload.wordDifferentialArray;
    },
    setTitleAndDescription: (state, action) => {
      if (action.payload.title !== undefined) {
        state.title = action.payload.title;
      }
      if (action.payload.description !== undefined) {
        state.description = action.payload.description;
      }
    },
    setDataFromLoadedActivity: (state, action) => {
      if (!action.payload) {
        state.status = null;
        state.secondsElapsed = 0;
        state.wordsAdded = 0;
        state.wordsDeleted = 0;
        state.activityID = undefined;
        state.title = undefined;
        state.description = undefined;
        state.secondsElapsedArray = undefined;
        state.wordDifferentialArray = undefined;
        state.checksum = "";
        state.loaded = true;
        return;
      }
      state.status = action.payload.status;
      state.activityID = action.payload.activityID;
      state.secondsElapsed = action.payload.secondsElapsed;
      state.wordsAdded = action.payload.wordsAdded;
      state.wordsDeleted = action.payload.wordsDeleted;
      state.loaded = true;
      state.title = action.payload.title;
      state.description = action.payload.description;
      state.checksum = action.payload.checksum;
    },
    updateChecksum: (state, action) => {
      state.checksum = action.payload;
    },
    setOutOfSync: (state) => {
      state.outOfSync = true;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  startRecording,
  pauseRecording,
  togglePauseRecording,
  incrementWordsAdded,
  incrementWordsDeleted,
  incrementSecondsElapsed,
  setDataFromLoadedActivity,
  initializeActivity,
  finishRecording,
  resetRecording,
  setChartData,
  setTitleAndDescription,
  updateChecksum,
  setOutOfSync,
  setUnloaded,
} = activitySlice.actions;

export default activitySlice.reducer;
