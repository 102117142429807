import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Input,
  Avatar,
} from "@nextui-org/react";
import { ArrowRight, Copy, CopySuccess } from "iconsax-react";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FriendInRoom, UserMetadata } from "../gql/graphql";
import ProfileAvatar from "./ProfileAvatar";

function SectionDivider() {
  return (
    <div
      style={{
        width: "100%",
        height: "1px",
        backgroundColor: "#E0E0E0",
        margin: "10px 0",
      }}
    />
  );
}

const GET_FRIENDS_IN_ROOM = gql`
  query GetFriendsInRoom($roomID: String!) {
    getFriendsInRoom(request: { roomID: $roomID }) {
      invitedFriends {
        username
        userID
        profilePhotoURL
        profilePlaceholder
      }
      joinedFriends {
        username
        userID
        profilePhotoURL
        profilePlaceholder
      }
    }
  }
`;

const SEARCH_FOR_USERS_TO_INVITE_TO_ROOM = gql`
  query SearchForUsersToInviteToRoom($query: String!, $roomID: String!) {
    searchForUsersToInviteToRoom(request: { query: $query, roomID: $roomID }) {
      username
      profilePhotoURL
      userID
      profilePlaceholder
    }
  }
`;

const INVITE_USER_TO_ROOM = gql`
  mutation InviteUserToRoom($roomID: String!, $username: String!) {
    inviteUserToRoom(request: { roomID: $roomID, username: $username }) {
      success
    }
  }
`;

export default function InviteButton() {
  const { roomID } = useParams();
  const [copied, setCopied] = useState(false);
  const [copyHovered, setCopyHovered] = useState(false);
  const [invitedFriends, setInvitedFriends] = useState<FriendInRoom[]>([]);
  const [joinedFriends, setJoinedFriends] = useState<FriendInRoom[]>([]);
  const { data, loading } = useQuery(GET_FRIENDS_IN_ROOM, {
    variables: { roomID },
  });
  const [inviteUserToRoom] = useMutation(INVITE_USER_TO_ROOM);

  const [searchQuery, setSearchQuery] = useState("");
  const [isResultsOpen, setIsResultsOpen] = useState(false);
  const [hoveredUsername, setHoveredUsername] = useState<string | null>(null);

  const [
    searchForFriends,
    { data: searchForFriendsData, loading: searchForFriendsLoading },
  ] = useLazyQuery(SEARCH_FOR_USERS_TO_INVITE_TO_ROOM, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setIsResultsOpen(true);
    },
  });

  const handleSearchFriends = useCallback(
    (query: string) => {
      setSearchQuery(query);
      if (query.length > 0) {
        searchForFriends({ variables: { query, roomID } });
      }
      if (query.length === 0) {
        setIsResultsOpen(false);
      }
    },
    [searchForFriends, roomID]
  );
  useEffect(() => {
    if (data && data.getFriendsInRoom) {
      setInvitedFriends(data.getFriendsInRoom.invitedFriends);
      setJoinedFriends(data.getFriendsInRoom.joinedFriends);
    }
  }, [data]);
  return (
    <div>
      <Popover
        onClose={() => {
          setCopied(false);
          setIsResultsOpen(false);
          setHoveredUsername(null);
        }}
      >
        <PopoverTrigger>
          <Button>
            <p className="font-sans">Invite</p>
          </Button>
        </PopoverTrigger>
        <PopoverContent>
          <div
            style={{
              width: 400,
              marginLeft: 6,
              marginRight: 6,
              marginBottom: 10,
              marginTop: 10,
            }}
          >
            <div className="flex flex-row">
              <p
                style={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                Invite link:{" "}
                {`${window.location.protocol}//${window.location.host}/room/${roomID}`}
              </p>
              <div
                style={{ cursor: "pointer", position: "relative" }}
                onMouseEnter={() => setCopyHovered(true)}
                onMouseLeave={() => setCopyHovered(false)}
                onClick={() => {
                  navigator.clipboard.writeText(
                    `${window.location.protocol}//${window.location.host}/room/${roomID}`
                  );
                  setCopied(true);
                }}
              >
                {copied ? (
                  <CopySuccess color={copyHovered ? "black" : "grey"} />
                ) : (
                  <Copy color={copyHovered ? "black" : "grey"} />
                )}
                {copied && (
                  <div
                    style={{
                      position: "absolute",
                      top: "-30px",
                      left: "50%",
                      transform: "translateX(-50%)",
                      backgroundColor: "black",
                    }}
                  >
                    <p className="font-sans" style={{ color: "white" }}>
                      Copied!
                    </p>
                  </div>
                )}
              </div>
            </div>
            <SectionDivider />
            <div className="flex flex-row">
              <div
                style={{ marginRight: 6, width: "100%", position: "relative" }}
              >
                <Input
                  placeholder="Search for friends to invite"
                  size="sm"
                  classNames={{
                    mainWrapper: "font-sans",
                    inputWrapper: "h-10",
                  }}
                  onChange={(e) => {
                    handleSearchFriends(e.target.value);
                    setHoveredUsername(null);
                  }}
                />
                {isResultsOpen && (
                  <div className="absolute w-full bg-white border border-gray-200 rounded-md shadow-lg mt-1 max-h-60 overflow-y-auto z-10">
                    {searchForFriendsData?.searchForUsersToInviteToRoom.length >
                    0 ? (
                      searchForFriendsData?.searchForUsersToInviteToRoom.map(
                        (user: UserMetadata) => (
                          <div
                            key={user.username}
                            className="flex items-center justify-between p-2 hover:bg-gray-100 cursor-pointer"
                            onMouseEnter={() =>
                              setHoveredUsername(user.username)
                            }
                            onMouseLeave={() => setHoveredUsername(null)}
                            style={{
                              backgroundColor:
                                hoveredUsername === user.username
                                  ? "#E0E0E0"
                                  : "white",
                            }}
                            onClick={async () => {
                              await inviteUserToRoom({
                                variables: { roomID, username: user.username },
                              });
                              setInvitedFriends([
                                ...invitedFriends,
                                {
                                  username: user.username,
                                  userID: user.userID,
                                  profilePhotoURL: user.profilePhotoURL,
                                  profilePlaceholder: user.profilePlaceholder,
                                },
                              ]);
                              setIsResultsOpen(false);
                              setHoveredUsername(null);
                              setSearchQuery("");
                            }}
                          >
                            <div className="flex flex-row items-center">
                              <ProfileAvatar
                                src={user.profilePhotoURL || undefined}
                                size="sm"
                                profilePlaceholder={user.profilePlaceholder}
                                style={{ height: 20, width: 20 }}
                              />
                              <span className="ml-2 font-sans">
                                {user.username}
                              </span>
                            </div>
                            <div className="flex flex-row items-center">
                              <span
                                className="ml-auto font-sans text-gray-500"
                                style={{ marginRight: 10 }}
                              >
                                Invite
                              </span>
                              <ArrowRight size={16} />
                            </div>
                          </div>
                        )
                      )
                    ) : (
                      <div style={{ top: 20, padding: 10 }}>
                        <p className="font-sans" style={{ color: "grey" }}>
                          No results found
                        </p>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            <SectionDivider />
            <div style={{ marginTop: 10 }}>
              <p className="font-sans" style={{ fontWeight: "bold" }}>
                Accepted invites
              </p>
              <div style={{ maxHeight: 200, overflowY: "auto" }}>
                {joinedFriends.length > 0 ? (
                  joinedFriends.map((friend) => (
                    <div
                      key={friend.userID}
                      className="flex items-center space-x-2"
                      style={{ marginBottom: 4 }}
                    >
                      <div style={{ marginRight: 6 }}>
                        <ProfileAvatar
                          src={friend.profilePhotoURL || undefined}
                          size="sm"
                          profilePlaceholder={friend.profilePlaceholder}
                          style={{ height: 32, width: 32 }}
                        />
                      </div>
                      <p className="font-sans">{friend.username}</p>
                    </div>
                  ))
                ) : (
                  <p className="font-sans text-gray-500 italic">
                    No accepted invites yet
                  </p>
                )}
              </div>
            </div>
            <div style={{ marginTop: 10 }}>
              <p className="font-sans" style={{ fontWeight: "bold" }}>
                Pending invites
              </p>
              <div style={{ maxHeight: 200, overflowY: "auto" }}>
                {invitedFriends.length > 0 ? (
                  invitedFriends.map((friend) => (
                    <div
                      key={friend.userID}
                      className="flex items-center space-x-2"
                      style={{ marginBottom: 4 }}
                    >
                      <div style={{ marginRight: 6 }}>
                        <ProfileAvatar
                          src={friend.profilePhotoURL || undefined}
                          size="sm"
                          profilePlaceholder={friend.profilePlaceholder}
                          style={{ height: 32, width: 32 }}
                        />
                      </div>
                      <p className="font-sans">{friend.username}</p>
                    </div>
                  ))
                ) : (
                  <p className="font-sans text-gray-500 italic">
                    No pending invites
                  </p>
                )}
              </div>
            </div>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
}
