import { gql, useQuery } from "@apollo/client";
import { Navigate, Outlet } from "react-router-dom";

const CHECK_LOGGED_IN = gql`
  query CheckLoggedIn {
    isLoggedIn
  }
`;

// wrap routes in this to ensure only authenticated users can access them
function ProtectedRoute({ redirectPath = "/login" }) {
  const {
    data,
    loading,
  }: { data?: { isLoggedIn: boolean }; loading: boolean } =
    useQuery(CHECK_LOGGED_IN);

  if (loading) {
    return <p>loading...</p>;
  }
  if (!data || !data.isLoggedIn) {
    return <Navigate to={redirectPath} replace />;
  }
  return <Outlet />;
}

export default ProtectedRoute;
