import { ActivityLog } from "../gql/graphql";

function parseTextToLinks(text: string, draftID?: string) {
  const regex = /\[([^\]]+)\]\(([^)]+)\)/g;
  const parts = [];
  let lastIndex = 0;

  let match;
  while ((match = regex.exec(text)) !== null) {
    const [fullMatch, linkText, linkUrl] = match;

    // Add any text before the match as a separate part
    if (match.index > lastIndex) {
      parts.push(text.slice(lastIndex, match.index));
    }

    // Add the anchor link as a React component
    parts.push(
      <a
        className="hover:underline"
        style={{ color: "#7194a8", fontWeight: "bold" }}
        key={match.index}
        href={
          linkUrl +
          `${draftID && linkUrl.includes("/room") ? `/draft/${draftID}` : ""}`
        }
      >
        {linkText}
      </a>
    );

    lastIndex = regex.lastIndex;
  }

  // Add any remaining text after the last match
  if (lastIndex < text.length) {
    parts.push(text.slice(lastIndex));
  }

  return <>{parts}</>;
}

export function ActivityLogFeedItem({
  activityLog,
  draftID,
}: {
  activityLog: ActivityLog;
  draftID?: string;
}) {
  return (
    <div className="font-sans" style={{ fontSize: 14, marginBottom: 8 }}>
      {parseTextToLinks(activityLog.templatedLog, draftID)}
    </div>
  );
}
