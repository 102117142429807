import {
  Autocomplete,
  AutocompleteItem,
  Avatar,
  Button,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Textarea,
} from "@nextui-org/react";
import Header from "./Header";
import { ArrowLeft, MessageQuestion, SearchNormal1 } from "iconsax-react";
import { useEffect, useMemo, useState } from "react";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { useDebouncedCallback } from "use-debounce";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import FriendRequestList from "./FriendRequestList";
import { useUserInfo } from "../hooks/useUserInfo";
import FeedbackButton from "./FeedbackButton";
import ProfileAvatar from "./ProfileAvatar";

const SEARCH_FOR_USERS = gql`
  query SearchForUsersGlobal($query: String!) {
    searchForUsers(request: { query: $query }) {
      username
      profilePhotoURL
      profilePlaceholder
    }
  }
`;

function HomepageHeader() {
  const [searchQuery, setSearchQuery] = useState("");

  const { isLoggedIn, settled } = useUserInfo();

  const isSmallMobile = useMediaQuery({
    query: "(max-width: 650px)",
  });
  const navigate = useNavigate();
  const [searchResults, setSearchResults] = useState<
    {
      username: string;
      profilePhotoURL: string | undefined;
      profilePlaceholder: string;
    }[]
  >([]);
  const [searchForUsers, { loading: searchDataLoading }] = useLazyQuery(
    SEARCH_FOR_USERS,
    {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        setSearchResults(data.searchForUsers);
      },
    }
  );

  const debouncedSearchForUsers = useDebouncedCallback(searchForUsers, 100);

  useEffect(() => {
    if (searchQuery) {
      debouncedSearchForUsers({
        variables: { query: searchQuery },
      });
    } else {
      setSearchResults([]);
    }
  }, [debouncedSearchForUsers, searchQuery]);

  const itemList = useMemo(() => {
    if (searchQuery === "") {
      return [];
    }
    if (searchDataLoading) {
      return [
        <AutocompleteItem key="loading" textValue="loading">
          <div className="flex items-center">Loading...</div>
        </AutocompleteItem>,
      ];
    }
    if (searchResults.length === 0) {
      return [
        <AutocompleteItem key="no-results" textValue="no results">
          <div className="flex items-center">No results found</div>
        </AutocompleteItem>,
      ];
    }
    return searchResults.map((result) => (
      <AutocompleteItem key={result.username} textValue={result.username}>
        <div
          className="flex items-center"
          onClick={() => {
            navigate(`/writer/${result.username}`);
          }}
        >
          <ProfileAvatar
            src={result.profilePhotoURL}
            size="sm"
            alt={result.username}
            profilePlaceholder={result.profilePlaceholder}
            style={{ height: 32, width: 32 }}
          />
          <p className="ml-2">{result.username}</p>
        </div>
      </AutocompleteItem>
    ));
  }, [navigate, searchDataLoading, searchQuery, searchResults]);

  return (
    <Header>
      <div className="flex flex-row justify-between items-center">
        <div
          className="flex flex-col ml-2 font-sans"
          style={{ maxWidth: 300, width: "100%", marginRight: 10 }}
        >
          <Autocomplete
            aria-labelledby="search-bar-input"
            placeholder="Search for writers"
            inputValue={searchQuery}
            onInputChange={(value) => setSearchQuery(value)}
            allowsCustomValue={true}
            disabledKeys={["no-results", "loading"]}
            startContent={<SearchNormal1 />}
            selectorIcon={null}
            onKeyDown={(e) => {
              if ("continuePropagation" in e) {
                e.continuePropagation();
              }
            }}
            onSelectionChange={() => {}}
            inputProps={{
              classNames: {
                inputWrapper: ["h-2.5"],
              },
            }}
            selectorButtonProps={{
              style: { display: "none" },
            }}
            selectedKey={null}
            menuTrigger="focus"
            defaultFilter={(item, query) => {
              return true;
            }}
          >
            {itemList}
          </Autocomplete>
        </div>

        <div className="flex flex-row items-center">
          {!isSmallMobile && (
            <div style={{ marginRight: 20 }}>
              <FeedbackButton />
            </div>
          )}
          {isLoggedIn && settled && <FriendRequestList />}
        </div>
      </div>
    </Header>
  );
}

export default HomepageHeader;
