import { gql, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { InfoCircle } from "iconsax-react";
import { useState } from "react";

const GET_ROOM_PERMANENCE_STATUS = gql`
  query GetRoomPermanenceStatus($roomID: String!) {
    getRoomData(request: { roomID: $roomID }) {
      permanent
    }
  }
`;

function RoomPermanenceIndicator() {
  const { roomID } = useParams();
  const { data, loading, error } = useQuery(GET_ROOM_PERMANENCE_STATUS, {
    variables: { roomID },
  });
  const [hovering, setHovering] = useState(false);

  if (loading) return null;
  if (error) return null;

  const isPermanent = data.getRoomData.permanent;

  if (isPermanent) return null;

  return (
    <div className="relative inline-block">
      <InfoCircle
        size={16}
        color="red"
        style={{ cursor: "help" }}
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
      />
      {hovering && (
        <div
          className="font-sans"
          style={{
            position: "absolute",
            top: 30,
            left: -80,
            backgroundColor: "black",
            color: "white",
            padding: "5px",
            borderRadius: "5px",
            width: "200px",
            textAlign: "center",
            fontSize: 16,
          }}
        >
          This room is ephemeral and will be deleted when everyone leaves.
        </div>
      )}
    </div>
  );
}

export default RoomPermanenceIndicator;
