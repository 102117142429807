import { AddSquare } from "iconsax-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import { WritingCircleMetadata } from "../gql/graphql";
import EarthBackground from "../assets/earth-background.png";
import { useUserInfo } from "../hooks/useUserInfo";

const GET_WRITING_CIRCLES = gql`
  query GetWritingCircles {
    getWritingCirclesForUser {
      circleID
      name
      imageDownloadURL
    }
  }
`;

export default function WritingCirclesList() {
  const [createNewHovered, setCreateNewHovered] = useState(false);
  const navigate = useNavigate();
  const { data, loading } = useQuery(GET_WRITING_CIRCLES, {
    fetchPolicy: "no-cache",
  });
  const { isLoggedIn } = useUserInfo();

  return (
    <div style={{ margin: 50 }}>
      <p className="text-xl font-sans" style={{ fontWeight: "bold" }}>
        Your writing circles
      </p>
      <div style={{ marginTop: 20 }} className="flex flex-wrap gap-4">
        {/* Existing writing circles */}
        {loading ? (
          <div style={{ height: 200 }} />
        ) : (
          data?.getWritingCirclesForUser.map(
            (circle: WritingCircleMetadata) => (
              <div
                key={circle.circleID}
                className="cursor-pointer"
                onClick={() => navigate(`/circle/${circle.circleID}`)}
                style={{
                  width: 200,
                  height: 200,
                  backgroundImage: `url(${
                    circle.imageDownloadURL || EarthBackground
                  })`,
                  backgroundSize: "cover",
                  borderRadius: 6,
                  display: "flex",
                  alignItems: "flex-end",
                }}
              >
                <div
                  className="flex flex-col blurred-div w-full"
                  style={{
                    margin: 10,
                    borderRadius: 5,
                    padding: "5px 10px",
                  }}
                >
                  <p
                    className="font-sans text-white"
                    style={{
                      fontWeight: "bold",
                      fontSize: 16,
                    }}
                  >
                    {circle.name}
                  </p>
                </div>
              </div>
            )
          )
        )}

        {/* Create new writing circle button */}
        <div
          onMouseEnter={() => setCreateNewHovered(true)}
          onMouseLeave={() => setCreateNewHovered(false)}
          className="flex flex-col items-center justify-center"
          style={{
            width: 200,
            height: 200,
            backgroundColor: createNewHovered ? "#7194A8" : "#d2d2d2",
            borderRadius: 6,
            cursor: isLoggedIn ? "pointer" : "not-allowed",
            position: "relative",
          }}
          onClick={() => {
            if (isLoggedIn) {
              navigate("/create-writing-circle");
            }
          }}
          data-testid="button-create-new-writing-circle"
        >
          <AddSquare color={"white"} size={80} style={{ strokeWidth: 0.5 }} />
          <p
            className="font-sans"
            style={{
              color: "white",
              fontSize: 16,
            }}
          >
            Create new writing circle
          </p>
          {!isLoggedIn && createNewHovered && (
            <div
              style={{
                position: "absolute",
                bottom: "100%",
                left: "50%",
                transform: "translateX(-50%)",
                backgroundColor: "rgba(0, 0, 0, 0.8)",
                color: "white",
                padding: "6px 6px",
                borderRadius: 4,
                marginBottom: 8,
                whiteSpace: "nowrap",
                zIndex: 1000,
              }}
            >
              <p style={{ fontSize: 12 }}>
                Login to create and access your writing circles
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
