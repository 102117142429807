import PlaintextTitleHeader from "../components/PlaintextTitleHeader";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useUserInfo } from "../hooks/useUserInfo";
import DraftSelectModal from "../components/DraftSelectModal";

export default function RoomPage() {
  const { roomID } = useParams();

  const { isLoggedIn, settled } = useUserInfo();

  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn && settled) {
      navigate(`/room/${roomID}/draft/new`, { replace: true });
    }
  }, [isLoggedIn, navigate, roomID, settled]);

  return (
    <div className="h-full">
      <PlaintextTitleHeader title="Joining room" />
      <DraftSelectModal
        open={true}
        setOpen={() => {}}
        roomID={roomID}
        dismissable={false}
      />
    </div>
  );
}
