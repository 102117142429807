import { gql, useMutation } from "@apollo/client";
import {
  Button,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Textarea,
} from "@nextui-org/react";
import { ArrowLeft, MessageQuestion } from "iconsax-react";
import { useState } from "react";

const SEND_FEEDBACK = gql`
  mutation SendFeedback($title: String!, $description: String!) {
    sendFeedback(request: { title: $title, description: $description }) {
      success
    }
  }
`;
export default function FeedbackButton() {
  const [feedbackTitle, setFeedbackTitle] = useState("");
  const [feedbackDescription, setFeedbackDescription] = useState("");
  const [feedbackSent, setFeedbackSent] = useState(false);
  const [sendFeedback, { loading }] = useMutation(SEND_FEEDBACK, {
    variables: { title: feedbackTitle, description: feedbackDescription },
    onCompleted: () => {
      setFeedbackTitle("");
      setFeedbackDescription("");
      setFeedbackSent(true);
    },
  });
  return (
    <Popover
      onClose={() => {
        setFeedbackSent(false);
      }}
    >
      <PopoverTrigger>
        <Button isIconOnly variant="light" data-testid="button-send-feedback">
          <MessageQuestion />
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <div className="flex flex-col" style={{ width: 200, height: 320 }}>
          <div style={{ marginTop: 10 }}>
            <p className="font-sans font-semibold">Send feedback</p>
          </div>
          {!feedbackSent && (
            <>
              <div
                className="font-sans"
                style={{ marginTop: 10, marginBottom: 10 }}
              >
                <Input
                  placeholder="title"
                  size="sm"
                  variant="bordered"
                  value={feedbackTitle}
                  onValueChange={(value) => setFeedbackTitle(value)}
                />
              </div>
              <div className="font-sans" style={{ marginBottom: 10 }}>
                <Textarea
                  size="sm"
                  variant="bordered"
                  placeholder="description"
                  className="flex-1 h-full font-sans"
                  minRows={30}
                  value={feedbackDescription}
                  onValueChange={(value) => setFeedbackDescription(value)}
                />
              </div>
              <Button
                size="sm"
                onClick={async () => {
                  if (feedbackTitle) {
                    await sendFeedback();
                  }
                }}
                isLoading={loading}
              >
                Submit
              </Button>
            </>
          )}
          {feedbackSent && (
            <div
              className="font-sans flex flex-col flex-1"
              style={{ marginTop: 10 }}
            >
              <div className="flex-1 flex flex-col justify-center items-center">
                <p>Thank you for the feedback!</p>
              </div>
              <Button
                size="sm"
                className="font-sans"
                color="primary"
                startContent={<ArrowLeft />}
                onClick={() => setFeedbackSent(false)}
              >
                Send more feedback
              </Button>
            </div>
          )}
        </div>
      </PopoverContent>
    </Popover>
  );
}
