import { useDropzone } from "react-dropzone";
import PlaintextTitleHeader from "../components/PlaintextTitleHeader";
import { useDispatch } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { ImageUploadLinkResponse } from "../gql/graphql";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { updateFromSettings, updatePhotoDownloadURL } from "../redux/userStore";
import { Avatar, Button, Input, Textarea } from "@nextui-org/react";
import { useUserInfo } from "../hooks/useUserInfo";
import { CloseCircle } from "iconsax-react";

const GET_PROFILE_PHOTO_UPLOAD_URL = gql`
  query GetImageUploadLink {
    getImageUploadLink {
      imageID
      link
      fields
    }
  }
`;

const SET_IMAGE_UPLOADED = gql`
  mutation SetImageUploaded($imageID: String!) {
    setImageUploaded(request: { imageID: $imageID }) {
      photoDownloadURL
    }
  }
`;

const GET_USER_INFO_SETTINGS = gql`
  query GetUserInfoSettings {
    getUserInfoSettings {
      username
      firstName
      lastName
      bio
      private
      link
    }
  }
`;

const UPDATE_USER_INFO_SETTINGS = gql`
  mutation UpdateUserInfoSettings(
    $username: String!
    $firstName: String!
    $lastName: String!
    $bio: String!
    $link: String
  ) {
    updateUserInfoSettings(
      request: {
        username: $username
        firstName: $firstName
        lastName: $lastName
        bio: $bio
        link: $link
      }
    ) {
      success
    }
  }
`;

const SET_PRIVATE = gql`
  mutation SetPrivate {
    setPrivate {
      success
    }
  }
`;

const SET_PUBLIC = gql`
  mutation SetPublic {
    setPublic {
      success
    }
  }
`;
const CLEAR_PROFILE_PICTURE = gql`
  mutation ClearProfilePicture {
    clearProfilePhoto {
      success
    }
  }
`;

type LocalUserState = {
  username: string;
  firstName: string;
  lastName: string;
  bio: string;
  link?: string;
  private: boolean;
};

function Settings() {
  const [clearProfilePicture] = useMutation(CLEAR_PROFILE_PICTURE);
  const { profilePhotoDownloadURL } = useUserInfo();
  const [getProfilePhotoUploadLink] = useLazyQuery(
    GET_PROFILE_PHOTO_UPLOAD_URL,
    {
      fetchPolicy: "no-cache",
    }
  );
  const [setImageUploaded] = useMutation(SET_IMAGE_UPLOADED);
  const [setPrivate] = useMutation(SET_PRIVATE);
  const [setPublic] = useMutation(SET_PUBLIC);
  const { data: userSettingsData } = useQuery(GET_USER_INFO_SETTINGS, {
    fetchPolicy: "no-cache",
  });
  const [updateUserInfoSettings] = useMutation(UPDATE_USER_INFO_SETTINGS);
  const dispatch = useDispatch();
  const [updateProfileButtonClicked, setUpdateProfileButtonClicked] =
    useState(false);
  const [localUserState, setLocalUserState] = useState<LocalUserState>({
    username: "",
    firstName: "",
    lastName: "",
    bio: "",
    private: false,
  });

  const [hoveringProfilePictureButton, setHoveringProfilePictureButton] =
    useState(false);
  const [hoveringProfilePictureAvatar, setHoveringProfilePictureAvatar] =
    useState(false);

  useEffect(() => {
    if (userSettingsData?.getUserInfoSettings) {
      setLocalUserState({
        username: userSettingsData.getUserInfoSettings.username,
        firstName: userSettingsData.getUserInfoSettings.firstName ?? "",
        lastName: userSettingsData.getUserInfoSettings.lastName ?? "",
        bio: userSettingsData.getUserInfoSettings.bio ?? "",
        private: userSettingsData.getUserInfoSettings.private ?? false,
        link: userSettingsData.getUserInfoSettings.link,
      });
    }
  }, [userSettingsData?.getUserInfoSettings]);

  const onDrop = useCallback(
    (acceptedFiles: any) => {
      if (acceptedFiles.length !== 1) {
        return;
      }
      // Do something with the files
      const reader = new FileReader();
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = async () => {
        const form = new FormData();
        // Do whatever you want with the file contents
        const binaryStr = reader.result as ArrayBuffer;
        // get url
        const response: {
          data: { getImageUploadLink: ImageUploadLinkResponse };
        } = await getProfilePhotoUploadLink();
        Object.entries(response.data.getImageUploadLink.fields).forEach(
          ([field, value]) => {
            form.append(field, value as string);
          }
        );
        form.append("file", new Blob([new Uint8Array(binaryStr)]));

        const fetchResult = await fetch(response.data.getImageUploadLink.link, {
          method: "POST",
          body: form,
        });
        if (fetchResult.ok) {
          const photoResponse = await setImageUploaded({
            variables: {
              imageID: response.data.getImageUploadLink.imageID,
            },
          });
          if (photoResponse.data?.setImageUploaded.photoDownloadURL) {
            dispatch(
              updatePhotoDownloadURL(
                photoResponse.data.setImageUploaded.photoDownloadURL
              )
            );
          }
        }
      };
      reader.readAsArrayBuffer(acceptedFiles[0]);
    },
    [dispatch, getProfilePhotoUploadLink, setImageUploaded]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
    maxSize: 1024 * 1024 * 5,
  });
  return (
    <div>
      <PlaintextTitleHeader title="Settings" />
      <div className="flex flex-col items-center">
        <div style={{ maxWidth: 600, width: '100%' }}>
          <div style={{ marginBottom: 20, padding: 15 }}>
            <p
              className="font-sans"
              style={{ fontSize: 20, fontWeight: "bold", marginBottom: 20 }}
            >
              General
            </p>
            <p className="font-sans">Username</p>
            <div style={{ marginBottom: 10 }}>
              <Input
                variant="bordered"
                size="sm"
                value={localUserState.username || ""}
                onValueChange={(value) => {
                  setLocalUserState((prev) => ({ ...prev, username: value }));
                }}
              />
            </div>
            <div style={{ marginBottom: 10 }}>
              <p className="font-sans">First name</p>
              <Input
                variant="bordered"
                size="sm"
                value={localUserState.firstName || ""}
                onValueChange={(value) => {
                  setLocalUserState((prev) => ({ ...prev, firstName: value }));
                }}
              />
            </div>
            <div style={{ marginBottom: 10 }}>
              <p className="font-sans">Last name</p>
              <Input
                variant="bordered"
                size="sm"
                value={localUserState.lastName || ""}
                onValueChange={(value) => {
                  setLocalUserState((prev) => ({ ...prev, lastName: value }));
                }}
              />
            </div>
            <div style={{ marginBottom: 10 }}>
              <p className="font-sans">Link</p>
              <Input
                variant="bordered"
                size="sm"
                value={localUserState.link || ""}
                onValueChange={(value) => {
                  setLocalUserState((prev) => ({ ...prev, link: value }));
                }}
              />
            </div>
            <div style={{ marginBottom: 10 }}>
              <p className="font-sans">Bio</p>
              <Textarea
                variant="bordered"
                size="sm"
                value={localUserState.bio || ""}
                onValueChange={(value) => {
                  setLocalUserState((prev) => ({ ...prev, bio: value }));
                }}
              />
            </div>
            <Button
              className="font-sans"
              onClick={async () => {
                await updateUserInfoSettings({
                  variables: {
                    username: localUserState.username,
                    firstName: localUserState.firstName,
                    lastName: localUserState.lastName,
                    bio: localUserState.bio,
                    link: localUserState.link,
                  },
                });
                setUpdateProfileButtonClicked(true);
                dispatch(
                  updateFromSettings({
                    username: localUserState.username,
                    firstName: localUserState.firstName,
                    lastName: localUserState.lastName,
                    bio: localUserState.bio,
                  })
                );
              }}
            >
              {updateProfileButtonClicked ? "Updated!" : "Update profile"}
            </Button>
          </div>
          <div style={{ padding: 20 }}>
            <p
              className="font-sans"
              style={{ fontSize: 20, fontWeight: "bold", marginBottom: 15 }}
            >
              {profilePhotoDownloadURL ? "Update" : "Set"} profile picture
            </p>
            {profilePhotoDownloadURL && (
              <div className="flex flex-row justify-center">
                <div
                  onMouseEnter={() => {
                    setHoveringProfilePictureAvatar(true);
                  }}
                  onMouseLeave={() => {
                    setHoveringProfilePictureAvatar(false);
                  }}
                  {...getRootProps()}
                  className="relative"
                  style={{ cursor: "pointer" }}
                >
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <Avatar
                      src={profilePhotoDownloadURL}
                      showFallback
                      style={{ width: 256, height: 256 }}
                    />
                  ) : (
                    <Avatar
                      src={profilePhotoDownloadURL}
                      showFallback
                      style={{ width: 256, height: 256 }}
                    />
                  )}
                  {hoveringProfilePictureAvatar && (
                    <div
                      className="relative flex flex-col justify-center items-center font-sans"
                      style={{
                        position: "absolute",
                        top: 105,
                        left: 82,
                        backgroundColor: "white",
                        borderWidth: 2,
                        borderColor: "black",
                        paddingLeft: 16,
                        paddingRight: 16,
                        borderRadius: 12,
                        height: 40,
                        fontSize: "0.875rem",
                      }}
                    >
                      Upload
                    </div>
                  )}
                  <Button
                    style={{ position: "absolute", top: 0, right: 0 }}
                    onClick={async () => {
                      await clearProfilePicture();
                      dispatch(updatePhotoDownloadURL(null));
                    }}
                    variant="light"
                    isIconOnly
                  >
                    <CloseCircle color="black" />
                  </Button>
                </div>
              </div>
            )}
            {!profilePhotoDownloadURL && (
              <div className="flex flex-row">
                <div
                  {...getRootProps()}
                  className="relative flex flex-col justify-center items-center"
                  style={{
                    cursor: "pointer",
                    height: 40,
                    backgroundColor: "#d4d4d8",

                    paddingLeft: 16,
                    paddingRight: 16,
                    borderRadius: 12,
                    opacity: hoveringProfilePictureButton ? 0.9 : 1,
                  }}
                  onMouseEnter={() => {
                    setHoveringProfilePictureButton(true);
                  }}
                  onMouseLeave={() => {
                    setHoveringProfilePictureButton(false);
                  }}
                >
                  <input {...getInputProps()} />
                  <p
                    className="font-sans"
                    style={{
                      fontSize: "0.875rem",
                      fontWeight: 400,
                    }}
                  >
                    Select picture
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Settings;
