import { useMemo } from "react";
import { FriendStatus, UserMetadata } from "../gql/graphql";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from "@nextui-org/react";
import { gql, useMutation } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import { useUserInfo } from "../hooks/useUserInfo";
import { useMediaQuery } from "react-responsive";

const SEND_FRIEND_REQUEST = gql`
  mutation SendFriendRequest($username: String!) {
    sendFriendRequest(request: { username: $username }) {
      success
    }
  }
`;

const REMOVE_FRIEND = gql`
  mutation RemoveFriend($username: String!) {
    removeFriend(request: { username: $username }) {
      success
    }
  }
`;

const ACCEPT_FRIEND_REQUEST = gql`
  mutation AcceptFriendRequest($username: String!) {
    acceptFriendRequest(request: { username: $username }) {
      success
    }
  }
`;

const DECLINE_FRIEND_REQUEST = gql`
  mutation DeclineFriendRequest($username: String!) {
    declineFriendRequest(request: { username: $username }) {
      success
    }
  }
`;

function RequestButton({
  setOtherUserInfoState,
  otherUserInfoState,
}: {
  setOtherUserInfoState: (args: {
    friendStatus: FriendStatus | null;
    private: boolean;
    friendCount: number;
    friends: UserMetadata[];
    link?: string;
    percentWordsThisWeek: number;
    percentWordsAllTime: number;
    sundialScore: number;
    totalWordsAdded: number;
    profilePlaceholder: string;
  }) => void;
  otherUserInfoState: {
    friendStatus: FriendStatus | null;
    private: boolean;
    friendCount: number;
    friends: UserMetadata[];
    link?: string;
    percentWordsThisWeek: number;
    percentWordsAllTime: number;
    sundialScore: number;
    totalWordsAdded: number;
    profilePlaceholder: string;
  };
}) {
  const { username } = useParams();
  const navigate = useNavigate();
  const { userID } = useUserInfo();
  const [sendFriendRequest] = useMutation(SEND_FRIEND_REQUEST);
  const [removeFriend] = useMutation(REMOVE_FRIEND);
  const [acceptFriendRequest] = useMutation(ACCEPT_FRIEND_REQUEST);
  const [declineFriendRequest] = useMutation(DECLINE_FRIEND_REQUEST);
  const followingButtonText = useMemo(() => {
    if (otherUserInfoState?.friendStatus === FriendStatus.Active) {
      return "Friends";
    } else if (otherUserInfoState?.friendStatus === FriendStatus.Pending) {
      return "Requested";
    } else if (
      otherUserInfoState?.friendStatus === FriendStatus.BeingRequested
    ) {
      return "Respond to request";
    } else {
      return "Send friend request";
    }
  }, [otherUserInfoState?.friendStatus]);
  return (
    <div style={{ width: "100%" }}>
      {otherUserInfoState.friendStatus === FriendStatus.Inactive && (
        <Button
          className="w-full font-sans"
          style={{
            height: 30,
          }}
          onClick={async () => {
            if (!userID) {
              navigate("/login");
              return;
            }
            await sendFriendRequest({ variables: { username } });
            setOtherUserInfoState({
              ...otherUserInfoState,
              friendStatus: FriendStatus.Pending,
            });
          }}
        >
          {followingButtonText}
        </Button>
      )}
      {otherUserInfoState.friendStatus !== FriendStatus.Inactive && (
        <Dropdown className="font-sans w-full" style={{ width: 256 }}>
          <DropdownTrigger>
            <Button
              className="w-full font-sans"
              style={{
                height: 30,
              }}
            >
              {followingButtonText}
            </Button>
          </DropdownTrigger>
          <DropdownMenu aria-labelledby="dropdown-menu" className="w-full">
            {otherUserInfoState.friendStatus === FriendStatus.Active ||
            otherUserInfoState.friendStatus === FriendStatus.Pending ? (
              <DropdownItem
                className="w-full"
                key="unfollow"
                onClick={async () => {
                  await removeFriend({ variables: { username } });
                  setOtherUserInfoState({
                    ...otherUserInfoState,
                    friendStatus: FriendStatus.Inactive,
                  });
                }}
              >
                {otherUserInfoState.friendStatus === FriendStatus.Active
                  ? "Unfriend"
                  : "Cancel request"}
              </DropdownItem>
            ) : (
              [
                <DropdownItem
                  className="w-full"
                  key="accept"
                  onClick={async () => {
                    await acceptFriendRequest({ variables: { username } });
                    setOtherUserInfoState({
                      ...otherUserInfoState,
                      friendStatus: FriendStatus.Active,
                    });
                  }}
                >
                  Accept request
                </DropdownItem>,
                <DropdownItem
                  className="w-full"
                  key="decline"
                  onClick={async () => {
                    await declineFriendRequest({ variables: { username } });
                    setOtherUserInfoState({
                      ...otherUserInfoState,
                      friendStatus: FriendStatus.Inactive,
                    });
                  }}
                >
                  Decline request
                </DropdownItem>,
              ]
            )}
          </DropdownMenu>
        </Dropdown>
      )}
    </div>
  );
}

export default RequestButton;
