export function getDraftPath({
  draftID,
  roomID,
}: {
  draftID: string;
  roomID?: string;
}) {
  if (roomID) {
    return `/room/${roomID}/draft/${draftID}`;
  }
  return `/draft/${draftID}`;
}
