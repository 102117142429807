import Header from "./Header";

function PlaintextTitleHeader({ title }: { title: string }) {
  return (
    <Header>
      <div className="ml-2">
        <p className="font-sans" style={{ fontWeight: "bold" }}>
          {title}
        </p>
      </div>
    </Header>
  );
}

export default PlaintextTitleHeader;
