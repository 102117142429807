import PlaintextTitleHeader from "../components/PlaintextTitleHeader";
import { ArrowRight, CloseCircle, InfoCircle } from "iconsax-react";
import {
  Input,
  Button,
  Switch,
  Textarea,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
} from "@nextui-org/react";
import { useEffect, useState, useRef, useMemo } from "react";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { ImageUploadLinkResponse } from "../gql/graphql";
import { useNavigate } from "react-router-dom";
import { useUserInfo } from "../hooks/useUserInfo";
import { useMediaQuery } from "react-responsive";

const GET_ROOM_IMAGE_PRESETS = gql`
  query GetRoomImagePresets {
    getRoomImagePresets {
      presetID
      imageURL
    }
  }
`;

const GENERATE_PROMPT = gql`
  query GeneratePrompt {
    generatePrompt {
      prompt
    }
  }
`;

const CREATE_ROOM = gql`
  mutation CreateRoom(
    $name: String!
    $presetID: String
    $imageParameters: ImageParameters
    $chatEnabled: Boolean!
    $prompt: String
    $timeLeftSeconds: Int
    $private: Boolean!
    $permanent: Boolean!
  ) {
    createRoom(
      request: {
        name: $name
        presetID: $presetID
        imageParameters: $imageParameters
        chatEnabled: $chatEnabled
        prompt: $prompt
        timeLeftSeconds: $timeLeftSeconds
        private: $private
        permanent: $permanent
      }
    ) {
      roomID
    }
  }
`;

const GET_IMAGE_UPLOAD_URL = gql`
  query GetImageUploadURL {
    getImageUploadLink {
      link
      imageID
      fields
    }
  }
`;

const GET_MOST_RECENTLY_EDITED_DRAFT = gql`
  query GetMostRecentlyEditedDraftCreateRoomPage {
    getMostRecentlyEditedDraft {
      draftID
    }
  }
`;

export default function CreateRoomPage() {
  const navigate = useNavigate();
  const [promptText, setPromptText] = useState("");
  const [isChatEnabled, setIsChatEnabled] = useState(true);
  const { isLoggedIn } = useUserInfo();
  const [isTimerEnabled, setIsTimerEnabled] = useState(true);
  const [durationHours, setDurationHours] = useState(0);
  const [durationMinutes, setDurationMinutes] = useState(30);
  const [roomName, setRoomName] = useState("New room");
  const [imageSelectorOpen, setImageSelectorOpen] = useState(false);
  const [createRoom] = useMutation(CREATE_ROOM);
  const [getImageUploadURL] = useLazyQuery(GET_IMAGE_UPLOAD_URL, {
    fetchPolicy: "no-cache",
  });
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isPrivate, setIsPrivate] = useState(true);
  const [privateInfoOpen, setPrivateInfoOpen] = useState(false);

  const isPermanent = useMemo(() => {
    return isLoggedIn;
  }, [isLoggedIn]);
  const isSmallScreen = useMediaQuery({
    query: "(max-width: 650px)",
  });

  const { data: mostRecentlyEditedDraftData } = useQuery(
    GET_MOST_RECENTLY_EDITED_DRAFT,
    {
      fetchPolicy: "no-cache",
    }
  );

  const { data, loading } = useQuery(GET_ROOM_IMAGE_PRESETS);

  // lazy query for generating prompt
  const [generatePrompt] = useLazyQuery(GENERATE_PROMPT, {
    fetchPolicy: "no-cache",
  });

  // const [setRoomImage] = useMutation(SET_ROOM_IMAGE);
  const [selectedRoomPresetID, setSelectedRoomPresetID] = useState("");

  useEffect(() => {
    if (data?.getRoomImagePresets.length > 0 && selectedRoomPresetID === "") {
      setSelectedRoomPresetID(data.getRoomImagePresets[0].presetID);
    }
  }, [data, selectedRoomPresetID]);

  const objectURL = useMemo(
    () => (selectedFile ? URL.createObjectURL(selectedFile) : ""),
    [selectedFile]
  );

  const selectedRoomImageURL = useMemo(() => {
    return selectedRoomPresetID === "custom"
      ? objectURL
      : data?.getRoomImagePresets.find(
          (preset: { presetID: string; imageURL: string }) =>
            preset.presetID === selectedRoomPresetID
        )?.imageURL || "";
  }, [selectedRoomPresetID, objectURL, data]);

  return (
    <div className="flex flex-col w-full h-full">
      <PlaintextTitleHeader title="Create room" />
      <Modal
        isOpen={imageSelectorOpen}
        onClose={() => setImageSelectorOpen(false)}
        size="2xl"
        style={{
          maxHeight: "60vh",
          overflowY: "auto",
        }}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader
                className="font-sans text-lg font-bold"
                style={{
                  position: "sticky",
                  top: 0,
                  backgroundColor: "white",
                  zIndex: 1,
                  padding: "16px",
                  borderBottom: "1px solid #e5e5e5",
                }}
              >
                Select room image
              </ModalHeader>
              <ModalBody>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(3, 1fr)",
                    gap: "16px",
                    padding: "16px",
                  }}
                >
                  {data?.getRoomImagePresets.map(
                    (preset: { presetID: string; imageURL: string }) => (
                      <div
                        key={preset.presetID}
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setSelectedRoomPresetID(preset.presetID);
                          setImageSelectorOpen(false);
                        }}
                      >
                        <div
                          style={{
                            backgroundImage: `url(${preset.imageURL})`,
                            backgroundSize: "cover",
                            width: "100%",
                            paddingBottom: "100%",
                            borderRadius: "8px",
                            transition: "opacity 0.2s",
                          }}
                          onMouseEnter={(e) =>
                            (e.currentTarget.style.opacity = "0.8")
                          }
                          onMouseLeave={(e) =>
                            (e.currentTarget.style.opacity = "1")
                          }
                        />
                      </div>
                    )
                  )}
                  <div
                    style={{
                      width: "100%",
                      position: "relative",
                      paddingTop: "100%",
                      borderRadius: "8px",
                      border: "2px dashed #ccc",
                      overflow: "hidden",
                    }}
                  >
                    <div
                      className="font-sans"
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 2,
                        backgroundImage: `url(${objectURL})`,
                        backgroundSize: "cover",
                        cursor: objectURL ? "pointer" : "default",
                      }}
                      onClick={() => {
                        if (objectURL) {
                          setSelectedRoomPresetID("custom");
                          setImageSelectorOpen(false);
                        }
                      }}
                    >
                      <div
                        className="flex flex-col items-center"
                        style={{
                          backgroundColor: "rgba(255, 255, 255, 0.9)",
                          boxShadow:
                            "0px 0px 25px 25px rgba(255, 255, 255, 0.9)",
                        }}
                      >
                        <Button
                          size="sm"
                          variant="bordered"
                          onClick={() => {
                            fileInputRef.current?.click();
                          }}
                        >
                          Choose File
                        </Button>
                        <span
                          style={{
                            marginTop: "8px",
                            fontSize: "12px",
                          }}
                        >
                          {selectedFile ? selectedFile.name : "No file chosen"}
                        </span>
                      </div>
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: "#f0f0f0",
                        zIndex: 1,
                      }}
                    />
                    <input
                      ref={fileInputRef}
                      type="file"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        const file = e.target.files?.[0];
                        if (file) {
                          setSelectedFile(file);
                          setSelectedRoomPresetID("custom");
                          setImageSelectorOpen(false);
                        }
                      }}
                    />
                  </div>
                </div>
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>
      <div className="flex flex-col flex-1 items-center">
        <div
          className="font-sans"
          style={{
            marginTop: 20,
            width: isSmallScreen ? "calc(100% - 60px)" : 400,
            maxWidth: "100%",
          }}
        >
          <div style={{ marginBottom: 20, fontSize: 20, fontWeight: "bold" }}>
            <p>Room settings</p>
          </div>
          <div
            className="setting-row flex flex-row"
            style={{ marginBottom: 20 }}
          >
            <div
              style={{
                backgroundImage: `url(${selectedRoomImageURL})`,
                backgroundSize: "cover",
                minWidth: 60,
                minHeight: 60,
                borderRadius: 10,
                marginBottom: 10,
                marginRight: 10,
              }}
              onClick={() => setImageSelectorOpen(true)}
              className="cursor-pointer"
            />
            <div
              className="flex flex-col"
              style={{ marginLeft: 10, width: "100%" }}
            >
              <div style={{ marginBottom: 6 }}>
                <p style={{ fontSize: 14 }}>Room name</p>
              </div>

              <Input
                value={roomName}
                onChange={(e) => setRoomName(e.target.value)}
                size="sm"
                style={{ fontSize: 14 }}
                classNames={{
                  inputWrapper: ["h-[24px]"],
                }}
                className="w-full"
              />
            </div>
          </div>

          <hr
            className="border-t border-gray-200"
            style={{ margin: "20px 0" }}
          />

          <div className="setting-row" style={{ marginBottom: 20 }}>
            <div className="flex justify-between items-center">
              <div style={{ marginRight: 20, fontSize: 14 }}>Timer</div>
              {isTimerEnabled ? (
                <div className="flex items-center" style={{ width: 250 }}>
                  <div className="flex items-center mr-2">
                    <Input
                      type="number"
                      value={durationHours.toString()}
                      onChange={(e) =>
                        setDurationHours(parseInt(e.target.value) || 0)
                      }
                      style={{ fontSize: 14 }}
                      min={0}
                      size="sm"
                      className="w-16"
                      classNames={{
                        inputWrapper: ["h-[24px]"],
                      }}
                    />
                    <p className="ml-1 mr-2" style={{ fontSize: 14 }}>
                      h
                    </p>
                  </div>
                  <div className="flex items-center mr-2">
                    <Input
                      type="number"
                      value={durationMinutes.toString()}
                      onChange={(e) => {
                        const totalMinutes = parseInt(e.target.value) || 0;
                        const newHours = Math.floor(totalMinutes / 60);
                        const newMinutes = totalMinutes % 60;
                        setDurationHours(durationHours + newHours);
                        setDurationMinutes(newMinutes);
                      }}
                      style={{ fontSize: 14 }}
                      min={0}
                      size="sm"
                      className="w-16"
                      classNames={{
                        inputWrapper: ["h-[24px]"],
                      }}
                    />
                    <p className="ml-1 mr-2" style={{ fontSize: 14 }}>
                      m
                    </p>
                  </div>
                  <Button
                    isIconOnly
                    size="sm"
                    variant="light"
                    onPress={() => setIsTimerEnabled(false)}
                    style={{ height: 34, fontSize: 14 }}
                  >
                    <CloseCircle size={20} color="grey" />
                  </Button>
                </div>
              ) : (
                <Button
                  size="sm"
                  variant="bordered"
                  style={{ height: 34, fontSize: 14 }}
                  onPress={() => setIsTimerEnabled(true)}
                >
                  Add
                </Button>
              )}
            </div>
          </div>

          <hr
            className="border-t border-gray-200"
            style={{ margin: "20px 0" }}
          />

          <div className="setting-row" style={{ marginBottom: 20 }}>
            <div
              className="flex justify-between items-center"
              style={{ marginBottom: 16 }}
            >
              <div>
                <p style={{ fontSize: 14 }}>Prompt</p>
                <p style={{ fontSize: 14, color: "grey" }}>
                  Generate or write your own
                </p>
              </div>
              <Button
                size="sm"
                style={{ height: 34, fontSize: 14 }}
                variant="bordered"
                onClick={async () => {
                  const { data } = await generatePrompt();
                  if (data) {
                    setPromptText(data.generatePrompt.prompt);
                  }
                }}
              >
                Generate
              </Button>
            </div>
            <div className="flex items-center w-full">
              <Textarea
                value={promptText}
                style={{ fontSize: 14 }}
                onChange={(e) => setPromptText(e.target.value)}
                placeholder="Enter prompt"
                className="flex-1"
                minRows={3}
                maxRows={5}
              />
            </div>
          </div>

          {isLoggedIn ? (
            <>
              <hr
                className="border-t border-gray-200"
                style={{ margin: "20px 0" }}
              />

              <div className="setting-row" style={{ marginBottom: 20 }}>
                <div className="flex justify-between items-center">
                  <div className="flex items-center relative">
                    <div style={{ fontSize: 14, marginRight: 10 }}>Unlisted</div>
                    <InfoCircle
                      size={14}
                      color="grey"
                      className="cursor-pointer"
                      onMouseEnter={() => setPrivateInfoOpen(true)}
                      onMouseLeave={() => setPrivateInfoOpen(false)}
                    />
                    {privateInfoOpen && (
                      <div
                        className="absolute"
                        style={{
                          top: 30,
                          left: 0,
                          backgroundColor: "black",
                          padding: 6,
                          width: 200,
                        }}
                      >
                        <p
                          className="font-sans"
                          style={{
                            fontSize: 14,
                            color: "white",
                            textAlign: "center",
                          }}
                        >
                          Unlisted rooms are not displayed on the public room list.
                        </p>
                      </div>
                    )}
                  </div>
                  <Switch
                    size="sm"
                    isSelected={isPrivate}
                    onValueChange={setIsPrivate}
                  />
                </div>
              </div>
            </>
          ) : null}

          <hr
            className="border-t border-gray-200"
            style={{ margin: "20px 0" }}
          />

          <div className="flex justify-end" style={{ marginTop: 40 }}>
            <Button
              data-testid="button-create-room"
              endContent={<ArrowRight size={20} />}
              color="primary"
              size="sm"
              style={{ height: 34, fontSize: 14 }}
              onClick={async () => {
                // need to upload image
                if (selectedRoomPresetID === "custom" && selectedFile) {
                  // upload image
                  const reader = new FileReader();
                  reader.readAsArrayBuffer(selectedFile);
                  reader.onload = async (e) => {
                    const form = new FormData();
                    // Do whatever you want with the file contents
                    const binaryStr = reader.result as ArrayBuffer;
                    // get url
                    const response: {
                      data: { getImageUploadLink: ImageUploadLinkResponse };
                    } = await getImageUploadURL();
                    Object.entries(
                      response.data.getImageUploadLink.fields
                    ).forEach(([field, value]) => {
                      form.append(field, value as string);
                    });
                    form.append("file", new Blob([new Uint8Array(binaryStr)]));

                    const fetchResult = await fetch(
                      response.data.getImageUploadLink.link,
                      {
                        method: "POST",
                        body: form,
                      }
                    );
                    if (fetchResult.ok) {
                      // proceed to room creation step
                      const { data } = await createRoom({
                        variables: {
                          name: roomName,
                          imageParameters: {
                            imageID: response.data.getImageUploadLink.imageID,
                            bucket:
                              response.data.getImageUploadLink.fields.bucket,
                            key: response.data.getImageUploadLink.fields.key,
                          },
                          chatEnabled: isChatEnabled,
                          prompt: promptText,
                          timeLeftSeconds: isTimerEnabled
                            ? durationHours * 3600 + durationMinutes * 60
                            : undefined,
                          private: isPrivate,
                          permanent: isPermanent,
                        },
                      });
                      if (data) {
                        // redirect to room
                        navigate(
                          `/room/${data.createRoom.roomID}${
                            mostRecentlyEditedDraftData
                              ?.getMostRecentlyEditedDraft?.draftID
                              ? `/draft/${mostRecentlyEditedDraftData?.getMostRecentlyEditedDraft?.draftID}`
                              : ""
                          }`
                        );
                      }
                    }
                  };
                } else {
                  // room creation with preset image
                  const { data } = await createRoom({
                    variables: {
                      name: roomName,
                      presetID: selectedRoomPresetID,
                      chatEnabled: isChatEnabled,
                      prompt: promptText,
                      timeLeftSeconds: isTimerEnabled
                        ? durationHours * 3600 + durationMinutes * 60
                        : undefined,
                      private: isPrivate,
                      permanent: isPermanent,
                    },
                  });
                  if (data) {
                    // redirect to room
                    navigate(
                      `/room/${data.createRoom.roomID}${
                        mostRecentlyEditedDraftData?.getMostRecentlyEditedDraft
                          ?.draftID
                          ? `/draft/${mostRecentlyEditedDraftData?.getMostRecentlyEditedDraft?.draftID}`
                          : ""
                      }`
                    );
                  }
                }
              }}
            >
              Create room
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
